
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ShopItem',
  props: {
    spuPicUrl: {
      type: String,
      default: '',
    },
    categoryName: {
      type: String,
      default: '',
    },
    spuName: {
      type: String,
      default: '',
    },
    price: [String, Number],
    onSelfTimes: [String, Number],
  },
});
