import { ref, Ref, watch } from 'vue';
import { HOT_SELLING_DATE_CYCLE_ENUM } from '../../constant';
import useDataOverview from './use-overview';
import useHotSellingTrend from './use-trend';
import useCategoryAnalysis from './use-category-analysis';
import usePriceAnalysis from './use-price-analysis';
import usePage from './use-page';
import { datefuns } from 'cx-utils';
import dayjs from 'dayjs';

export default function useHotSellingAnalysis(storeId: Ref<string>, channelId: Ref<string>) {
  const hotSellingAnalysisDate = ref<Date>(new Date());
  const hotSellingAnalysisCycle = ref<HOT_SELLING_DATE_CYCLE_ENUM>(HOT_SELLING_DATE_CYCLE_ENUM.WEEK);
  const searchDate = ref({
    startDate: datefuns.formatTime(hotSellingAnalysisDate.value as Date, 'YYYYMMDD'),
    endDate: datefuns.formatTime(hotSellingAnalysisDate.value as Date, 'YYYYMMDD'),
  });
  const handleChangeData = () => {
    const date = new Date(hotSellingAnalysisDate.value as Date);
    if (hotSellingAnalysisCycle.value === HOT_SELLING_DATE_CYCLE_ENUM.DATE) {
      searchDate.value = {
        startDate: datefuns.formatTime(date, 'YYYYMMDD'),
        endDate: datefuns.formatTime(date, 'YYYYMMDD'),
      };
    } else {
      const day = date.getDay();
      const startDate = new Date(date);
      startDate.setDate(startDate.getDate() - day + 1);
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 6);
      searchDate.value = {
        startDate: datefuns.formatTime(startDate, 'YYYYMMDD'),
        endDate: datefuns.formatTime(endDate, 'YYYYMMDD'),
      };
    }
  };
  const hotSellingAnalysisCategory = ref('-99');
  const {
    hotSellingAnalysisOverviewData,
    handleGetHotSellingAnalysisOverview,
  } = useDataOverview(storeId, channelId, searchDate, hotSellingAnalysisCategory);
  const {
    hotSellingTrendData,
    handleGetHotSellingTrend,
  } = useHotSellingTrend(storeId, channelId, searchDate, hotSellingAnalysisCategory);
  const {
    hotSellingAnalysisCategoryData,
    handleGetHotSellingCategoryAnalysis,
  } = useCategoryAnalysis(storeId, channelId, searchDate, hotSellingAnalysisCategory);
  const {
    hotSellingAnalysisPriceData,
    handleGetHotSellingPriceAnalysis,
  } = usePriceAnalysis(storeId, channelId, searchDate, hotSellingAnalysisCategory);
  const {
    hotSellingAnalysisPageData,
    hotSellingAnalysisPageParams,
    handleGetHotSellingAnalysisPage,
  } = usePage(storeId, channelId, searchDate, hotSellingAnalysisCategory);
  const loadHotSellingAnalysis = (pageNum?: number) => {
    handleChangeData();
    handleGetHotSellingAnalysisOverview();
    handleGetHotSellingTrend();
    handleGetHotSellingCategoryAnalysis();
    handleGetHotSellingPriceAnalysis();
    if (pageNum && pageNum !== hotSellingAnalysisPageParams.value.pageNum) {
      // 重置页码，如果页码有发生改变，则通过hook中的watch来执行查询
      hotSellingAnalysisPageParams.value.pageNum = pageNum;
    } else {
      handleGetHotSellingAnalysisPage();
    }
  };
  watch(
    hotSellingAnalysisCycle,
    () => {
      hotSellingAnalysisDate.value = new Date();
    },
  );
  watch(
    hotSellingAnalysisCategory,
    () => {
      loadHotSellingAnalysis(1);
    },
  );
  watch(
    hotSellingAnalysisDate,
    () => {
      loadHotSellingAnalysis(1);
    },
  );
  return {
    hotSellingAnalysisDate,
    hotSellingAnalysisCategory,
    hotSellingAnalysisOverviewData,
    hotSellingTrendData,
    hotSellingAnalysisCycle,
    hotSellingAnalysisCategoryData,
    hotSellingAnalysisPriceData,
    hotSellingAnalysisPageData,
    hotSellingAnalysisPageParams,
    loadHotSellingAnalysis,
  };
}
