import { IColumnsItem } from '@/components/custom-table/package/type.d';
import { INewCommodityItem } from '@/modules/information-manage/shop-manage/api/type.d';
import ShopItem from '../../shop-item/index.vue';
import { TableColumn } from '../type.d';

export const renderColumns = (
  columnSortList: TableColumn[],
  handleShowLog: (row: INewCommodityItem) => void,
) => {
  const columnMap: Record<TableColumn, IColumnsItem<INewCommodityItem>> = {
    orderNum: {
      label: '序号',
      prop: 'orderNum',
      width: '64',
    },
    product: {
      label: '商品',
      width: '344',
      render: row => (
        <ShopItem
          price={row.trdSalesSpuPri}
          spuPicUrl={row.spuLink}
          categoryName={row.categoryClass1Name}
          spuName={row.spuName}
          onSelfTimes={row.itmShelfSpuCnt}
        />
      ),
    },
    itmShelfSpuDate: {
      label: '上架时间',
      prop: 'itmShelfSpuDate',
    },
    trdSalesSpuNnum1d: {
      label: '首日销量',
      prop: 'trdSalesSpuNnum1d',
    },
    trdSalesSpuNumFirst30wi: {
      label: '上新30天销量',
      prop: 'trdSalesSpuNumFirst30wi',
    },
    trdSalesSpuNum: {
      label: '总销量',
      prop: 'trdSalesSpuNum',
    },
    trdSalesSpuAmtFirst30wi: {
      label: '上新30天销售额(万元)',
      prop: 'trdSalesSpuAmtFirst30wi',
    },
    trdCllctSpuCntFirst1d: {
      label: '首日收藏量',
      prop: 'trdCllctSpuCntFirst1d',
    },
    developments: {
      label: '最近上下架动态',
      render: row => (
        <div>
          首次上架 { row.itmShelfSpuDate }
          <el-button style="margin-left: 8px" type="text" onClick={() => handleShowLog(row)}>查看</el-button>
        </div>
      ),
    },
    itmLatelyShelfSpuDate: {
      label: '最近上架时间',
      prop: 'itmLatelyShelfSpuDate',
    },
    itmLatelyOffSpuDate: {
      label: '最近下架时间',
      prop: 'itmLatelyOffSpuDate',
    },
    srvcCommentCnt: {
      label: '总评价',
      prop: 'srvcCommentCnt',
    },
  };
  const column: IColumnsItem<INewCommodityItem>[] = [];
  columnSortList.forEach((columnSort) => {
    const col = columnMap[columnSort];
    col && column.push(columnMap[columnSort]);
  });
  return column;
};
