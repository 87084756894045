export enum QUREY_TYPE_ENUM {
  /**
   * 飙升商品数
   */
  GOODS_COUNT = 'GOODS_COUNT',
}

export const QUREY_TYPE_LIST = [
  { label: '飙升商品数', value: QUREY_TYPE_ENUM.GOODS_COUNT },
];

export enum DATE_ENUM {
  /**
   * 近3天
   */
   THREE_DAY = '1',
}

export const DATE_LIST = [
  { value: DATE_ENUM.THREE_DAY, label: '近3天' },
];
