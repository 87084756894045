import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70788046"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$slots.header || _ctx.header)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "flex card-header",
          style: _normalizeStyle(_ctx.headerStyle)
        }, [
          _renderSlot(_ctx.$slots, "header", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.header), 1)
          ], true)
        ], 4))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "card-body",
      style: _normalizeStyle(_ctx.bodyStyle)
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 4)
  ]))
}