import { ref, Ref } from 'vue';
import { getHotSellingAnalysisTrend } from '@/modules/information-manage/shop-manage/api';
import { IHotSellingTrend } from '@/modules/information-manage/shop-manage/api/type.d';

export default function useTrend(
  storeId: Ref<string>,
  channelId: Ref<string>,
  date: Ref<{ startDate: string; endDate: string; }>,
  category: Ref<string>,
) {
  // 商店热销趋势详情
  const hotSellingTrendData = ref<IHotSellingTrend[]>([]);
  // 获取商店热销趋势详情
  const handleGetHotSellingTrend = async () => {
    const { data } = await getHotSellingAnalysisTrend({
      storeId: storeId.value,
      channelId: channelId.value,
      ...date.value,
      categoryClass2: category.value,
    });
    hotSellingTrendData.value = data;
  };
  return { hotSellingTrendData, handleGetHotSellingTrend };
}
