import { ref, Ref, watch } from 'vue';
import { getHotSellingAnalysisPage } from '@/modules/information-manage/shop-manage/api';
import { IHotSellingAnalysisPageItem } from '@/modules/information-manage/shop-manage/api/type.d';

export default function usePage(
  storeId: Ref<string>,
  channelId: Ref<string>,
  date: Ref<{ startDate: string; endDate: string; }>,
  category: Ref<string>,
) {
  const hotSellingAnalysisPageParams = ref({
    pageNum: 1,
    pageSize: 10,
    total: 0,
  });
  // 热销商品清单
  const hotSellingAnalysisPageData = ref<IHotSellingAnalysisPageItem[]>([]);
  // 获取热销商品清单
  const handleGetHotSellingAnalysisPage = async () => {
    const { data } = await getHotSellingAnalysisPage({
      storeId: storeId.value,
      channelId: channelId.value,
      ...date.value,
      categoryClass2: category.value,
      pageNum: hotSellingAnalysisPageParams.value.pageNum,
      pageSize: hotSellingAnalysisPageParams.value.pageSize,
    });
    hotSellingAnalysisPageData.value = data.rows;
    hotSellingAnalysisPageParams.value.total = data.totalNum;
  };
  watch(
    () => hotSellingAnalysisPageParams.value.pageNum,
    () => {
      handleGetHotSellingAnalysisPage();
    },
  );
  return { hotSellingAnalysisPageData, hotSellingAnalysisPageParams, handleGetHotSellingAnalysisPage };
}
