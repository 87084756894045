import { ref, Ref } from 'vue';
import { getHotSellingAnalysisCategoryAnalysis } from '@/modules/information-manage/shop-manage/api';
import { IHotSellingAnalysisCategoryAnalysis } from '@/modules/information-manage/shop-manage/api/type.d';

export default function useCategoryAnalysis(
  storeId: Ref<string>,
  channelId: Ref<string>,
  date: Ref<{ startDate: string; endDate: string; }>,
  category: Ref<string>,
) {
  // 商店热销品类分析详情
  const hotSellingAnalysisCategoryData = ref<IHotSellingAnalysisCategoryAnalysis[]>([]);
  // 获取商店热销品类分析详情
  const handleGetHotSellingCategoryAnalysis = async () => {
    const { data } = await getHotSellingAnalysisCategoryAnalysis({
      storeId: storeId.value,
      channelId: channelId.value,
      ...date.value,
      categoryClass2: category.value,
    });
    hotSellingAnalysisCategoryData.value = data;
  };
  return { hotSellingAnalysisCategoryData, handleGetHotSellingCategoryAnalysis };
}
