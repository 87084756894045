
import { defineComponent, PropType } from 'vue';
import { IShopDetail } from '@/modules/information-manage/shop-manage/api/type.d';

export default defineComponent({
  name: 'ShopDetail',
  props: {
    shopDetailData: {
      type: Object as PropType<IShopDetail>,
      default: () => {
        return {
          storeId: '',
          storeName: '',
          storePictureLink: '',
          storeUrl: '',
          channelId: '',
          channelName: '',
          storeType: '',
          mainCategory: '',
          collectDate: '',
        };
      },
    },
  },
});
