import { ref, Ref, watch } from 'vue';
import { getShopTopSpu } from '@/modules/information-manage/shop-manage/api';
import { IShopTopNSpu, IShopTopSpuReq } from '@/modules/information-manage/shop-manage/api/type.d';
import {
  TOP_N_ORDER_ENUM,
  TOP_N_CALCUTE_TYPE_ENUM,
} from '@/modules/information-manage/shop-manage/views/detail/constant';

export default function useTopNSpu(storeId: Ref<string>, channelId: Ref<string>) {
  // 商店top100
  const shopTopData = ref<IShopTopNSpu[]>([]);
  const shopTopOrder = ref({
    orderColumn: TOP_N_ORDER_ENUM.ALL,
    orderType: '',
  });
  const shopTopPageParams = ref({
    pageNum: 1,
    pageSize: 10,
    total: 0,
  });
  const shopTopCalcuteType = ref<TOP_N_CALCUTE_TYPE_ENUM>(TOP_N_CALCUTE_TYPE_ENUM.YESTERDAY);
  // 品类
  const shopTopCategoryClass = ref('-99');
  // 获取商店top100
  const handleGetTopNSpu = async () => {
    const { data } = await getShopTopSpu({
      storeId: storeId.value,
      channelId: channelId.value,
      categoryClass2: shopTopCategoryClass.value,
      orderColumn: shopTopOrder.value.orderColumn,
      orderType: shopTopOrder.value.orderType as IShopTopSpuReq['orderType'],
      calcuteType: shopTopCalcuteType.value,
      pageNum: shopTopPageParams.value.pageNum,
      pageSize: shopTopPageParams.value.pageSize,
    });
    shopTopData.value = data.rows;
    shopTopPageParams.value.total = data.totalNum;
  };
  watch(
    () => shopTopPageParams.value.pageNum,
    () => {
      handleGetTopNSpu();
    },
  );
  const resetPageNum = () => {
    if (shopTopPageParams.value.pageNum !== 1) {
      shopTopPageParams.value.pageNum = 1;
    } else {
      handleGetTopNSpu();
    }
  };
  watch(
    shopTopCalcuteType,
    () => {
      resetPageNum();
    },
  );
  watch(
    shopTopOrder,
    () => {
      resetPageNum();
    },
  );
  watch(
    shopTopCategoryClass,
    () => {
      resetPageNum();
    },
  );
  return {
    shopTopCategoryClass,
    shopTopData,
    shopTopOrder,
    shopTopPageParams,
    shopTopCalcuteType,
    handleGetTopNSpu,
  };
}
