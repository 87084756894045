import { Ref } from 'vue';
import useSalesOverview from './use-sales-overview';
import useSalesTrend from './use-sales-trend';
import useUpDownTrend from './use-up-down-trend';
import useTopNSpu from './use-top-n-spu';

export default function useShopOverview(storeId: Ref<string>, channelId: Ref<string>) {
  return {
    ...useSalesOverview(storeId, channelId),
    ...useSalesTrend(storeId, channelId),
    ...useUpDownTrend(storeId, channelId),
    ...useTopNSpu(storeId, channelId),
  };
}
