export enum SALES_TREND_ENUM {
  /**
   * 销量
   */
  SALES_VOLUME = 'trdSalesSpuNumTd',
  /**
   * 销售额
   */
  VALUE_OF_SALES = 'trdSalesSpuAmtTd',
  /**
   * 上新商品数
   */
  NEW = 'itmShelfSpuCntFirstTd',
}

export const SALES_TREND_LIST = [
  { label: '销量', value: SALES_TREND_ENUM.SALES_VOLUME },
  { label: '销售额', value: SALES_TREND_ENUM.VALUE_OF_SALES },
  { label: '上新商品数', value: SALES_TREND_ENUM.NEW },
];

export enum DATE_ENUM {
  /**
   * 近7天
   */
  SEVEN_DAY = '1',
  /**
   * 近30天
   */
  THIRTY_DAY = '2',
}

export const DATE_LIST = [
  { value: DATE_ENUM.SEVEN_DAY, label: '近7天' },
  { value: DATE_ENUM.THIRTY_DAY, label: '近30天' },
];
