
import { defineComponent, PropType, computed } from 'vue';
import { TAB_LIST, TAB_ENUM } from '@/modules/information-manage/shop-manage/views/detail/constant';
import usePermissionConfig from '@/modules/information-manage/use-permission-config';

export default defineComponent({
  name: 'Tabs',
  emits: ['change', 'update:modelValue'],
  props: {
    modelValue: {
      type: String as PropType<TAB_ENUM>,
      default: TAB_ENUM.SHOP_OVERVIEW,
    },
  },
  setup(props, { emit }) {
    const permissionConfig = usePermissionConfig();

    const activeTabs = computed(() => {
      const list = TAB_LIST.filter((item) => {
        switch (item.value) {
          case TAB_ENUM.SHOP_OVERVIEW:
            return permissionConfig.DETAIL.value;
          case TAB_ENUM.NEW_COMMODITY_ANALYSIS:
            return permissionConfig.VIEW_SXFX.value;
          case TAB_ENUM.HOT_SELLING_ANALYSIS:
            return permissionConfig.VIEW_RXFX.value;
          case TAB_ENUM.SALES_SURGE_ANALYSIS:
            return permissionConfig.VIEW_XLBSFX.value;
          case TAB_ENUM.CATEGORY_ANALYSIS:
            return permissionConfig.VIEW_PLFX.value;
          case TAB_ENUM.ATTRIBUTE_ANALYSIS:
            return permissionConfig.VIEW_ATTRFX.value;
          case TAB_ENUM.PRICE_ANALYSIS:
            return permissionConfig.VIEW_JGFX.value;
          default:
            return false;
        }
      });
      return list;
    });
    const handleTabChange = (tab: TAB_ENUM) => {
      if (props.modelValue === tab) {
        return;
      }
      emit('update:modelValue', tab);
      emit('change', tab);
    };

    return {
      TAB_LIST,
      TAB_ENUM,
      activeTabs,
      handleTabChange,
    };
  },
});
