
import { defineComponent, ref, onMounted, watch } from 'vue';
import {
  DS_ORDER_ENUM,
} from '@/modules/information-manage/shop-manage/constant';
import {
  SALES_TREND_LIST,
  DATE_LIST,
  ALL,
} from './constant';
import Card from '../card/index.vue';
import TabList from '../tab-list/index.vue';
import PieChart from './components/pie-chart.vue';
import LineChart from './components/line-chart.vue';
import { useData } from './composables';
import { useCategoryOptions } from './composables/use-category-options';

export default defineComponent({
  name: 'shopOverview',
  emits: [],
  components: {
    Card,
    PieChart,
    TabList,
    LineChart,
  },
  setup(props, { emit }) {
    const category = ref<string>(ALL);
    const categoryOptions = ref<{label:string; value:string;}[]>([]);
    const distributeChartEl = ref<InstanceType<typeof PieChart>|null>(null);
    const reSelectLegend = () => {
      distributeChartEl.value?.selectAllLegend();
    };
    const {
      params,
      hotParams,
      distributionParams,
      overViewParams,
      handleChangeDate,
      handleChangeSort,
      currendHotData,
      currendDistributeData,
      hotSelectKeys,
      handleChangeHotSelectKeys,
      handleChangeHotTab,
      overViewTotal,
      overViewData,
      overViewPages,
      handleOverViewCurrentChange,
      selectedCategory,
    } = useData(reSelectLegend);
    const {
      getCategoryOptions,
    } = useCategoryOptions();

    const handleCategoryChange = (value: string) => {
      category.value = value;
      distributeChartEl.value?.selectAllLegend();
    };
    watch(category, (newVal, oldVal) => {
      selectedCategory.value = newVal;
    }, { immediate: true });
    onMounted(async () => {
      categoryOptions.value = await getCategoryOptions();
    });

    return {
      distributeChartEl,
      handleOverViewCurrentChange,
      overViewTotal,
      overViewData,
      overViewPages,
      handleChangeHotTab,
      hotParams,
      distributionParams,
      overViewParams,
      handleChangeSort,
      handleChangeDate,
      params,
      DATE_LIST,
      SALES_TREND_LIST,
      DS_ORDER_ENUM,
      currendHotData,
      handleChangeHotSelectKeys,
      hotSelectKeys,
      currendDistributeData,
      category,
      categoryOptions,
      handleCategoryChange,
    };
  },
});
