
import { defineComponent, PropType, computed, ref, watch, nextTick } from 'vue';
import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { LineChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from 'echarts/components';
import { IHotLineChartData } from '../types';

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default defineComponent({
  name: 'LineChart',
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    seriesConfig: {
      type: Object as PropType<IHotLineChartData>,
      default: () => ({}),
    },
    legendData: {
      type: Array as PropType<string[]>,
      default: () => ([]),
    },
    cates: {
      type: Array as PropType<string[]>,
      default: () => ([]),
    },
  },
  components: {
    VChart,
  },
  setup(props) {
    const chartEl = ref<InstanceType <typeof VChart>>();
    const option = computed(() => {
      const {
        seriesConfig: {
          series,
          xData,
          yAxisName,
        },
      } = props;
      const baseOption = {
        title: {
          text: '',
          left: 'center',
        },
        // color: ['#647DFA'],
        tooltip: {
          trigger: 'axis',
          formatter: (params: any) => {
            const name = params[0].axisValueLabel;
            const list: string[] = params
              .sort((a:any, b:any) => b.data - a.data)
              .map((v: any) => `${v.marker}${v.seriesName}：${v.data}`);
            return `${name}<br>${list.join('<br>')}`;
          },
        },
        grid: {
          left: '80',
          right: '40',
          top: '30',
          bottom: 0,
          containLabel: true,
        },
        legend: {
          show: false,
          orient: 'vertical',
          left: 'left',
          // data: [props.legendName],
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xData,
        },
        yAxis: {
          type: 'value',
          scale: true,
          name: yAxisName,
          nameTextStyle: {
            align: 'right',
            padding: [0, 0, 0, 0],
          },
        },
        series: series.map(v => ({
          name: v.name,
          data: v.data,
          type: 'line',
          smooth: true,
          // areaStyle: {
          //   opacity: 0.3,
          // },
        })),
      };
      return props.options ? Object.assign(baseOption, props.options) : baseOption;
    });
    watch(() => props.legendData, async (newVal) => {
      setTimeout(() => {
        props.cates?.forEach((v) => {
          chartEl.value?.dispatchAction({
            type: 'legendUnSelect',
            name: v,
          });
        });
        newVal.forEach((v) => {
          chartEl.value?.dispatchAction({
            type: 'legendSelect',
            name: v,
          });
        });
      }, 100);
    }, {
      immediate: true,
      deep: true,
    });
    return {
      option,
      chartEl,
    };
  },
  render() {
    return (
      <div class="chart-wrapper">
        {
          this.seriesConfig.series.length === 0
            ? (
              <empty content="暂无数据" />
            )
            : (
              <v-chart
                class="line-chart"
                option={this.option}
                autoresize={true}
                ref="chartEl"
              />
            )
        }
      </div>
    );
  },
});
