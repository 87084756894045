import { ref, computed } from 'vue';
import {
  QUREY_TYPE_ENUM,
  DATE_ENUM,
  DATE_LIST,
} from '../constant';
import {
  IGlobalParams,
  IAnalysisMap,
  IAnalysisData,
  IGoodsDetailItem,
  IDataItem,
} from '../types';
import * as API from '@/modules/information-manage/shop-manage/api';
import { useRoute } from 'vue-router';
import {
  IStoreSurgeOverviewDetailRes,
  IStoreSurgeOverviewDetailResItem,
  IStoreSurgeSpuPageReq,
} from '@/modules/information-manage/shop-manage/api/type.d';
import { cloneDeep } from 'lodash-es';
import { useList } from '@/hooks/use-list';
import { plus } from 'number-precision';

export const useData = () => {
  const route = useRoute();
  const storeId = route.query.storeId as string;
  const channelId = route.query.channelId as string;

  const params = ref<IGlobalParams>({
    date: DATE_ENUM.THREE_DAY,
    category: '-99',
  });

  // 数据概览
  const overViewData = ref<IStoreSurgeOverviewDetailRes>([]);
  // 获取概览数据
  const getSurgeOverviewData = async () => {
    const { data } = await API.getSurgeOverviewApi({
      storeId,
      channelId,
      categoryClass2: params.value.category,
    });
    overViewData.value = data;
  };
  const defaultOverViewList = [
    {
      label: '本期飙升商品数',
      value: '',
      description: '',
      blockColor: '#F5AB18',
      tips: '在售商品中近3天比前一期销量增长TOP前10%的商品，统称为飙升商品',
      valueKey: 'trdSalesSpuCntSurge3d',
    },
    {
      label: '本期飙升销量',
      value: '',
      tips: '本期飙升商品的增长销量总和',
      blockColor: '#EA8181',
      description: '',
      valueKey: 'trdSalesSpuNumSurge3d',
    },
    {
      label: '本期平均销量飙升值',
      value: '',
      tips: '本期飙升商品的平均增长销量',
      blockColor: '#D167EA',
      description: '',
      valueKey: 'trdSalesSpuNumSurgeAvg3d',
    },
    {
      label: '本期飙升商品品类数',
      value: '',
      tips: '本期飙升商品的品类数',
      blockColor: '#6C67EA',
      description: '',
      valueKey: 'trdSalesCategoryCntSurge3d',
    },
  ];
  const overviewDataList = computed(() => {
    const overview = overViewData.value?.[0] || {};
    const lists = cloneDeep(defaultOverViewList);
    lists.forEach((list) => {
      const valueKey = list.valueKey as keyof IStoreSurgeOverviewDetailResItem;
      list.value = overview[valueKey] || '0';
    });
    return lists;
  });

  const categoryAnalysisParams = ref({
    state: QUREY_TYPE_ENUM.GOODS_COUNT,
  });
  // 品类分布数据
  const categoryAnalysisData = ref<IAnalysisMap>({
    [QUREY_TYPE_ENUM.GOODS_COUNT]: {
      tooltipTitle: '商品数占比',
      unit: '件',
      data: [],
    },
  });
  // 当前展示的品类分布数据
  const currentCategoryAnalysisData = computed(() => {
    return categoryAnalysisData.value[categoryAnalysisParams.value.state];
  });
  // 飙升品类分析
  const getSurgeCategoryAnalysisData = async () => {
    const { data } = await API.getSurgeCategoryAnalysisApi({
      storeId,
      channelId,
      categoryClass2: params.value.category,
    });
    categoryAnalysisData.value[QUREY_TYPE_ENUM.GOODS_COUNT].data = data.map(v => ({
      name: v.categoryClass2Name,
      value: Number(v.trdSalesSpuCntSurge3d),
    })).sort((a, b) => b.value - a.value);
    // console.log('飙升品类分析', data);
  };
  const priceRangeAnalysisDataParams = ref({
    state: QUREY_TYPE_ENUM.GOODS_COUNT,
  });
  // 飙升价格带数据
  const priceRangeAnalysisData = ref<IAnalysisMap>({
    [QUREY_TYPE_ENUM.GOODS_COUNT]: {
      tooltipTitle: '商品数占比',
      unit: '件',
      legendData: [],
      data: [],
    },
  });
  // 当前展示的飙升价格带数据
  const currentPriceRangeAnalysisData = computed(() => {
    return priceRangeAnalysisData.value[priceRangeAnalysisDataParams.value.state];
  });
  // 价格带多选的排序算法
  const rangeSort = (list: IDataItem[]) => {
    // store = [闭区间范围, 开区间范围（以上｜以下）]
    const store:Array<IDataItem[]> = [[], []];
    list.forEach((item) => {
      if (item.name.indexOf('-') === -1) {
        store[1].push(item);
      } else {
        store[0].push(item);
      }
    });
    store[0].sort((a, b) => {
      return Number(a.name.split('-')[0]) - Number(b.name.split('-')[0]);
    });
    store[1].sort((a, b) => {
      return parseFloat(a.name) - parseFloat(b.name);
    });
    return [...store[0], ...store[1]];
  };
  // 飙升价格带分析
  const getSurgePriceAnalysisData = async () => {
    const { data } = await API.getSurgePriceAnalysisApi({
      storeId,
      channelId,
      categoryClass2: params.value.category,
    });
    const list:IAnalysisData['data'] = [];
    data.forEach((v) => {
      const row = list.find(it => it.name === v.priceRange);
      if (row) {
        row.value = plus(v.trdSalesSpuCntSurge3d, row.value);
      } else {
        list.push({
          name: v.priceRange,
          value: v.trdSalesSpuCntSurge3d,
        });
      }
    });
    priceRangeAnalysisData.value[QUREY_TYPE_ENUM.GOODS_COUNT].data = list.sort((a, b) => b.value - a.value);
    priceRangeAnalysisData.value[QUREY_TYPE_ENUM.GOODS_COUNT].legendData = rangeSort(list).reverse();
    // console.log('飙升价格带分析', data);
  };

  const {
    params: goodsDetailParams,
    tableData: goodsDetailData,
    handleSearch: handleSearchGoodsDetailData,
    tableTotal: goodsDetailTotal,
    handleCurrentChange: handleGoodsDetailCurrentChange,
  } = useList<IGoodsDetailItem, IStoreSurgeSpuPageReq>({
    request: {
      api: API.getSurgeSpuApi,
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      handleParams(customParams) {
        return {
          ...customParams,
          storeId,
          channelId,
          categoryClass2: params.value.category,
        };
      },
    },
    response: {
      tableDataKey: 'data.rows',
      totalKey: 'data.totalNum',
    },
  });

  // 获取所有请求数据
  const getData = () => {
    getSurgeOverviewData();
    getSurgeCategoryAnalysisData();
    getSurgePriceAnalysisData();
    handleSearchGoodsDetailData();
  };

  return {
    currentCategoryAnalysisData,
    categoryAnalysisParams,
    currentPriceRangeAnalysisData,
    priceRangeAnalysisDataParams,

    goodsDetailParams,
    goodsDetailData,
    goodsDetailTotal,
    handleGoodsDetailCurrentChange,

    DATE_LIST,
    params,
    overviewDataList,
    getData,
  };
};
