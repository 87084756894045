import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f0314626"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex flex-dir-column flex-justify-between" }
const _hoisted_3 = { class: "name" }
const _hoisted_4 = { class: "flex flex-justify-between flex-align-end" }
const _hoisted_5 = { class: "flex flex-dir-column" }
const _hoisted_6 = { class: "price" }
const _hoisted_7 = { class: "category" }
const _hoisted_8 = {
  key: 0,
  class: "category"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_image = _resolveComponent("custom-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_custom_image, {
      class: "spu-img",
      src: _ctx.spuPicUrl,
      fit: "cover"
    }, null, 8, ["src"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.spuName), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.price), 1),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.categoryName), 1)
        ]),
        (_ctx.onSelfTimes)
          ? (_openBlock(), _createElementBlock("span", _hoisted_8, "上架次数 " + _toDisplayString(_ctx.onSelfTimes), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}