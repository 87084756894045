import { ref, Ref } from 'vue';
import { getNewCommodityPriceAnalysis } from '@/modules/information-manage/shop-manage/api';
import { INewCommodityPriceAnalysis } from '@/modules/information-manage/shop-manage/api/type.d';
import { datefuns } from 'cx-utils';

export default function usePriceAnalysis(
  storeId: Ref<string>,
  channelId: Ref<string>,
  date: Ref<string[]>,
  category: Ref<string>,
) {
  // 上新价格分析详情
  const newCommodityPriceAnalysisData = ref<INewCommodityPriceAnalysis[]>([]);
  // 获取上新价格分析详情
  const handleGetNewCommodityPriceAnalysis = async () => {
    const { data } = await getNewCommodityPriceAnalysis({
      storeId: storeId.value,
      channelId: channelId.value,
      startDate: date.value[0] ? datefuns.formatTime(date.value[0], 'YYYYMMDD') : '',
      endDate: date.value[1] ? datefuns.formatTime(date.value[1], 'YYYYMMDD') : '',
      categoryClass2: category.value,
    });
    newCommodityPriceAnalysisData.value = data;
  };
  return {
    newCommodityPriceAnalysisData,
    handleGetNewCommodityPriceAnalysis,
  };
}
