import { has } from '@/core/plugins/filter';
import { computed } from 'vue';

const usePermissionConfig = () => {
  return {
    /*
    * 店铺分析
    * 控制：设计中心-情报中心-全部店铺-店铺分析
    * 跳转路由名称：InformationManageShopManageDetail
    * */
    DETAIL: computed(() => has('MOKEN-SJZX-QBZX-QBDP-DPFX')),
    /*
    * 店铺概况
    * 控制：设计中心-情报中心-全部店铺-店铺详情-店铺概况
    * 跳转路由名称：
    * */
    // VIEW_DPGL: computed(() => has('MOKEN-SJZX-QBZX-QBDP-DPGL')),
    /*
    * 上新分析
    * 控制：设计中心-情报中心-全部店铺-店铺详情-上新分析
    * 跳转路由名称：
    * */
    VIEW_SXFX: computed(() => has('MOKEN-SJZX-QBZX-QBDP-SXFX')),
    /*
    * 热销分析
    * 控制：设计中心-情报中心-全部店铺-店铺详情-热销分析
    * 跳转路由名称：
    * */
    VIEW_RXFX: computed(() => has('MOKEN-SJZX-QBZX-QBDP-RXFX')),
    /*
   * 销量飙升分析
   * 控制：设计中心-情报中心-全部店铺-店铺详情-销量飙升分析
   * 跳转路由名称：
   * */
    VIEW_XLBSFX: computed(() => has('MOKEN-SJZX-QBZX-QBDP-XLBSFX')),
    /*
    * 品类分析
    * 控制：设计中心-情报中心-全部店铺-店铺详情-品类分析
    * 跳转路由名称：
    * */
    VIEW_PLFX: computed(() => has('MOKEN-SJZX-QBZX-QBDP-PLFX')),
    /*
    * 属性分析
    * 控制：设计中心-情报中心-全部店铺-店铺详情-属性分析
    * 跳转路由名称：
    * */
    VIEW_ATTRFX: computed(() => has('MOKEN-SJZX-QBZX-QBDP-ATTRFX')),
    /*
    * 价格分析
    * 控制：设计中心-情报中心-全部店铺-店铺详情-价格分析
    * 跳转路由名称：
    * */
    VIEW_JGFX: computed(() => has('MOKEN-SJZX-QBZX-QBDP-JGFX')),
    /*
    * 关注/取消关注
    * 控制：设计中心-情报中心-全部店铺-关注/取消关注
    * 跳转路由名称：
    * */
    FOCUS: computed(() => has('MOKEN-SJZX-QBZX-QBDP-GZQXGZ')),
    /*
    * -添加收录
    * 控制：设计中心-情报中心-全部店铺-添加收录
    * 跳转路由名称：
    * */
    COLLECT: computed(() => has('MOKEN-SJZX-QBZX-QBDP-TJSL')),
  };
};

export default usePermissionConfig;
