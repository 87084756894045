import { ref, Ref, watch } from 'vue';
import { getNewCommodityPage } from '@/modules/information-manage/shop-manage/api';
import { INewCommodityItem } from '@/modules/information-manage/shop-manage/api/type.d';
import { datefuns } from 'cx-utils';

export default function usePage(
  storeId: Ref<string>,
  channelId: Ref<string>,
  date: Ref<string[]>,
  category: Ref<string>,
) {
  const newCommodityPageParams = ref({
    pageNum: 1,
    pageSize: 10,
    total: 0,
  });
  // 上新商品清单
  const newCommodityPageData = ref<INewCommodityItem[]>([]);
  // 获取上新商品清单
  const handleGetNewCommodityPage = async () => {
    const { data } = await getNewCommodityPage({
      storeId: storeId.value,
      channelId: channelId.value,
      startDate: date.value[0] ? datefuns.formatTime(date.value[0], 'YYYYMMDD') : '',
      endDate: date.value[1] ? datefuns.formatTime(date.value[1], 'YYYYMMDD') : '',
      categoryClass2: category.value,
      pageNum: newCommodityPageParams.value.pageNum,
      pageSize: newCommodityPageParams.value.pageSize,
    });
    newCommodityPageData.value = data.rows;
    newCommodityPageParams.value.total = data.totalNum;
  };
  watch(
    () => newCommodityPageParams.value.pageNum,
    () => {
      handleGetNewCommodityPage();
    },
  );
  return { newCommodityPageData, newCommodityPageParams, handleGetNewCommodityPage };
}
