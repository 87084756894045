import { ref, Ref } from 'vue';
import { getSalesOverview } from '@/modules/information-manage/shop-manage/api';
import { ISalesOverview } from '@/modules/information-manage/shop-manage/api/type.d';

export default function useSalesOverview(storeId: Ref<string>, channelId: Ref<string>) {
  // 商店销售概况
  const shopSalesOverviewData = ref<ISalesOverview>({} as ISalesOverview);
  // 获取商店销售概况
  const handleGetSalesOverview = async () => {
    const { data } = await getSalesOverview({ storeId: storeId.value, channelId: channelId.value });
    [shopSalesOverviewData.value] = data;
  };
  return { shopSalesOverviewData, handleGetSalesOverview };
}
