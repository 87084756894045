
import { defineComponent, computed, PropType, watch, ref, nextTick } from 'vue';
import Card from '../card/index.vue';
import DataItem from '../data-item/index.vue';
import {
  IHotSellingOverview,
  IHotSellingTrend,
  IHotSellingAnalysisCategoryAnalysis,
  IHotSellingAnalysisPriceAnalysis,
  IHotSellingAnalysisPageItem,
} from '@/modules/information-manage/shop-manage/api/type.d';
import {
  HOT_SELLING_DATE_CYCLE_ENUM,
  HOT_SELLING_DATE_CYCLE_LIST,
} from '@/modules/information-manage/shop-manage/views/detail/constant';
import { datefuns, objectfuns } from 'cx-utils';
import { TREND_ENUM, TREND_LIST } from './constant';
import { ITrendDataMap } from './type.d';
import TabList from '../tab-list/index.vue';
import LineChart from './components/line-chart.vue';
import PieChart from './components/pie-chart.vue';
import ShopItem from '../shop-item/index.vue';
import { ElTable } from 'element-plus';

export default defineComponent({
  name: 'HotSellingAnalysis',
  emits: [
    'update:date',
    'update:cycle',
    'update:category',
    'update:pageParams',
    'on-show-log',
  ],
  components: {
    Card,
    DataItem,
    TabList,
    LineChart,
    PieChart,
    ShopItem,
  },
  props: {
    height: {
      type: Number,
      default: 400,
    },
    date: {
      type: Date,
      default: new Date(),
    },
    category: {
      type: String,
      default: '',
    },
    cycle: {
      type: String as PropType<HOT_SELLING_DATE_CYCLE_ENUM>,
      default: HOT_SELLING_DATE_CYCLE_ENUM.DATE,
    },
    pageParams: {
      type: Object,
      default: () => {
        return {
          pageNum: 1,
          pageSize: 10,
          total: 0,
        };
      },
    },
    categoryOptions: {
      type: Array as PropType<{ label: string; value: string; }[]>,
      default: () => [],
    },
    overview: {
      type: Object as PropType<IHotSellingOverview>,
    },
    trendData: {
      type: Array as PropType<IHotSellingTrend[]>,
      default: () => [],
    },
    categoryAnalysis: {
      type: Array as PropType<IHotSellingAnalysisCategoryAnalysis[]>,
      default: () => [],
    },
    priceAnalysis: {
      type: Array as PropType<IHotSellingAnalysisPriceAnalysis[]>,
      default: () => [],
    },
    tableData: {
      type: Array as PropType<IHotSellingAnalysisPageItem[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const handleCategoryChange = (category: string) => {
      emit('update:category', category);
    };
    const handleCyleChange = (cycle: HOT_SELLING_DATE_CYCLE_ENUM) => {
      emit('update:cycle', cycle);
    };
    const handleDateChange = (date: any) => {
      emit('update:date', date);
    };
    const showDateValue = computed(() => {
      const date = new Date(props.date.getTime());
      let str = '';
      if (props.cycle === HOT_SELLING_DATE_CYCLE_ENUM.DATE) {
        str = datefuns.formatTime(date, 'YYYY-MM-DD');
      } else {
        const day = date.getDay();
        const startDate = new Date(date);
        startDate.setDate(startDate.getDate() - day + 1);
        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 6);
        str = `${datefuns.formatTime(startDate, 'YYYY-MM-DD')} - ${datefuns.formatTime(endDate, 'YYYY-MM-DD')}`;
      }
      return str;
    });
    // 数据概览
    const dataOverviewList = computed(() => {
      const list = [
        {
          label: '热销商品销售额（万元）',
          value: '0',
          blockColor: '#F56C6C',
          tips: '时间范围内店铺热销商品的销售额总和。',
          valueKey: 'trdSalesSpuAmtHp',
        },
        {
          label: '热销商品平均销售贡献（万元）',
          value: '0',
          tips: '时间范围内店铺平均每个热销商品的销售额，平均销售贡献=热销商品销售额/热销商品数。',
          blockColor: '#F59D62',
          valueKey: 'trdSalesSpuAvgContribHp',
        },
        {
          label: '热销商品数',
          value: '0',
          tips: '时间范围内店铺热销商品的总数。',
          blockColor: '#628CF5',
          valueKey: 'trdSalesSpuCntHp',
        },
        {
          label: '热销商品品类数',
          value: '0',
          tips: '时间范围内店铺热销商品数的品类数。',
          blockColor: '#CC62F5',
          valueKey: 'trdSalesSpuCategoryCntHp',
        },
      ];
      if (props.overview) {
        const { overview } = props;
        list.forEach((data) => {
          const valueKey = data.valueKey as keyof IHotSellingOverview;
          data.value = overview[valueKey];
        });
      }
      return list;
    });
    // 趋势
    const trendState = ref<TREND_ENUM>(TREND_ENUM.SALES_VOLUME);
    const trendXAxisData = ref<string[]>([]);
    const trendDataMap = ref<ITrendDataMap>({} as ITrendDataMap);
    trendDataMap.value[TREND_ENUM.SALES_VOLUME] = {
      seriesData: [],
      yAxisName: '销量',
      legendName: '销量',
    };
    trendDataMap.value[TREND_ENUM.VALUE_OF_SALES] = {
      seriesData: [],
      yAxisName: '销售额（万元）',
      legendName: '销售额（万元）',
    };
    watch(
      () => props.trendData,
      () => {
        trendXAxisData.value = [];
        trendDataMap.value[TREND_ENUM.SALES_VOLUME].seriesData = [];
        trendDataMap.value[TREND_ENUM.VALUE_OF_SALES].seriesData = [];
        if (props.trendData.length > 0) {
          props.trendData.forEach((trend) => {
            const { ds, trdSalesSpuNumHp, trdSalesSpuAmtHp } = trend;
            trendXAxisData.value.push(ds);
            trendDataMap.value[TREND_ENUM.SALES_VOLUME].seriesData.push(trdSalesSpuNumHp);
            trendDataMap.value[TREND_ENUM.VALUE_OF_SALES].seriesData.push(trdSalesSpuAmtHp);
          });
        }
      },
      { immediate: true },
    );
    // 品类分析
    const categoryList = [
      { label: '销售额', value: '1' },
    ];
    const categoryAnalysisPieData = computed(() => {
      const list: IHotSellingAnalysisCategoryAnalysis[] = objectfuns.deepClone(props.categoryAnalysis);
      return list.sort((a, b) => {
        return Number(b.trdSalesSpuAmtHp) - Number(a.trdSalesSpuAmtHp);
      }).map((data) => {
        const { trdSalesSpuAmtHp, categoryClass2Name } = data;
        return {
          value: trdSalesSpuAmtHp,
          name: categoryClass2Name,
        };
      });
    });
    // 价格分析
    const priceLegendData = computed(() => {
      return props.priceAnalysis.map((data) => {
        return {
          name: data.priceRange,
        };
      }).sort((a, b) => {
        return parseInt(b.name, 10) - parseInt(a.name, 10);
      });
    });
    const priceAnalysisPieData = computed(() => {
      const list: IHotSellingAnalysisPriceAnalysis[] = objectfuns.deepClone(props.priceAnalysis);
      return list.sort((a, b) => {
        return Number(b.trdSalesSpuAmtHp) - Number(a.trdSalesSpuAmtHp);
      }).map((data) => {
        const { trdSalesSpuAmtHp, priceRange } = data;
        return {
          value: trdSalesSpuAmtHp,
          name: priceRange,
        };
      });
    });
    // 修改pageNum
    const tableRef = ref<typeof ElTable | null>(null);
    const tableScrollEl = computed(() => {
      if (tableRef.value) {
        const el = tableRef.value.$el as Element;
        const scrollBody = el.getElementsByClassName('el-table__body-wrapper')[0];
        return scrollBody;
      }
      return null;
    });
    const handleCurrentChange = (pageNum: number) => {
      const params = Object.assign({}, props.pageParams);
      params.pageNum = pageNum;
      emit('update:pageParams', params);
    };
    const handleShowLog = (row: IHotSellingAnalysisPageItem) => {
      emit('on-show-log', row.spu);
    };
    const isShowTable = ref(true);
    watch(() => props.height, () => {
      isShowTable.value = false;
      nextTick(() => {
        isShowTable.value = true;
      });
    }, { immediate: true });
    return {
      dataOverviewList,
      handleCategoryChange,
      handleCyleChange,
      handleDateChange,
      HOT_SELLING_DATE_CYCLE_LIST,
      showDateValue,
      TREND_LIST,
      trendDataMap,
      trendXAxisData,
      trendState,
      categoryAnalysisPieData,
      priceAnalysisPieData,
      categoryList,
      tableRef,
      tableScrollEl,
      handleCurrentChange,
      handleShowLog,
      isShowTable,
      priceLegendData,
    };
  },
});
