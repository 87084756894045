import { ref, Ref } from 'vue';
import { getHotSellingAnalysisPriceAnalysis } from '@/modules/information-manage/shop-manage/api';
import { IHotSellingAnalysisPriceAnalysis } from '@/modules/information-manage/shop-manage/api/type.d';

export default function usePriceAnalysis(
  storeId: Ref<string>,
  channelId: Ref<string>,
  date: Ref<{ startDate: string; endDate: string; }>,
  category: Ref<string>,
) {
  // 商店热销价格分析详情
  const hotSellingAnalysisPriceData = ref<IHotSellingAnalysisPriceAnalysis[]>([]);
  // 获取商店热销价格分析详情
  const handleGetHotSellingPriceAnalysis = async () => {
    const { data } = await getHotSellingAnalysisPriceAnalysis({
      storeId: storeId.value,
      channelId: channelId.value,
      ...date.value,
      categoryClass2: category.value,
    });
    hotSellingAnalysisPriceData.value = data;
  };
  return { hotSellingAnalysisPriceData, handleGetHotSellingPriceAnalysis };
}
