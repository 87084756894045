
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Card',
  props: {
    header: {
      type: String,
      default: '',
    },
    headerStyle: {
      type: [String, Object, Array],
      default: '',
    },
    bodyStyle: {
      type: [String, Object, Array],
      default: '',
    },
  },
});
