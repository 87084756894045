
import { defineComponent, PropType, computed } from 'vue';
import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from 'echarts/components';

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default defineComponent({
  name: 'BarChart',
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    xAxisData: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    legendName: {
      type: String,
      default: '',
    },
    yAxisName: {
      type: String,
      default: '',
    },
    seriesData: {
      type: Array as PropType<string[] | number[]>,
      default: () => [],
    },
  },
  components: {
    VChart,
  },
  setup(props) {
    const option = computed(() => {
      const baseOption = {
        title: {
          text: props.title,
          left: 'center',
        },
        color: ['#7A8FF5'],
        tooltip: {
          trigger: 'item',
          formatter: (params: any) => {
            return `${params.name}<br>${params.marker}${params.seriesName} ${params.value}`;
          },
        },
        grid: {
          left: '40',
          right: '10',
          bottom: 0,
          containLabel: true,
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: [props.legendName],
        },
        xAxis: {
          type: 'category',
          data: props.xAxisData,
        },
        yAxis: {
          type: 'value',
          name: props.yAxisName,
          nameTextStyle: {
            align: 'right',
            padding: [0, 0, 0, 0],
          },
        },
        series: [
          {
            name: props.legendName,
            data: props.seriesData,
            type: 'bar',
            barWidth: 20,
          },
        ],
      };
      return props.options ? Object.assign(baseOption, props.options) : baseOption;
    });
    return {
      option,
    };
  },
  render() {
    return (
      <div class="chart-wrapper">
        {
          this.seriesData.length === 0
            ? <empty content="暂无数据" />
            : <v-chart class="bar-chart" option={this.option} autoresize={true} />
        }
      </div>
    );
  },
});
