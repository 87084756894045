
import { defineComponent } from 'vue';
import {
  DS_ORDER_ENUM,
} from '@/modules/information-manage/shop-manage/constant';
import {
  SALES_TREND_LIST,
  DATE_LIST,
} from './constant';
import Card from '../card/index.vue';
import TabList from '../tab-list/index.vue';
import PieChart from './components/pie-chart.vue';
import LineChart from './components/line-chart.vue';
import { useData } from './composables';

export default defineComponent({
  name: 'shopOverview',
  emits: [],
  components: {
    Card,
    PieChart,
    TabList,
    LineChart,
  },
  setup() {
    const {
      params,
      hotParams,
      distributionParams,
      overViewParams,
      handleChangeDate,
      handleChangeSort,
      currendHotData,
      currendDistributeData,
      hotSelectKeys,
      handleChangeHotSelectKeys,
      handleChangeHotTab,
      overViewTotal,
      overViewData,
      overViewPages,
      handleOverViewCurrentChange,
    } = useData();
    return {
      handleOverViewCurrentChange,
      overViewTotal,
      overViewData,
      overViewPages,
      handleChangeHotTab,
      hotParams,
      distributionParams,
      overViewParams,
      handleChangeSort,
      handleChangeDate,
      params,
      DATE_LIST,
      SALES_TREND_LIST,
      DS_ORDER_ENUM,
      currendHotData,
      handleChangeHotSelectKeys,
      hotSelectKeys,
      currendDistributeData,
    };
  },
});
