import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ba891fd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex tab-wrapper" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeTabs, (tab) => {
      return (_openBlock(), _createElementBlock("div", {
        key: tab.value,
        class: _normalizeClass({
        'tab': true,
        'selected': _ctx.modelValue === tab.value,
      }),
        onClick: ($event: any) => (_ctx.handleTabChange(tab.value))
      }, _toDisplayString(tab.label), 11, _hoisted_2))
    }), 128))
  ]))
}