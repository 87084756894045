import { ref, Ref } from 'vue';
import { getNewCommodityOverview } from '@/modules/information-manage/shop-manage/api';
import { INewCommodityDataOverview } from '@/modules/information-manage/shop-manage/api/type.d';
import { datefuns } from 'cx-utils';

export default function useOverview(
  storeId: Ref<string>,
  channelId: Ref<string>,
  date: Ref<string[]>,
  category: Ref<string>,
) {
  // 上新概览详情
  const newCommodityAnalysisOverviewData = ref<INewCommodityDataOverview>({
    startDate: '',
    endDate: '',
    channelId: '',
    channelName: '',
    storeId: '',
    storeName: '',
    categoryClass1: '',
    categoryClass1Name: '',
    itmShelfSpuCntFirst1d: '',
    itmShelfSpuCntFirst1dRate: '',
    trdSalesSpuNumFirst1d: '',
    trdSalesSpuNumFirst1dRate: '',
    trdSalesSpuAmtFirst1d: '',
    trdSalesSpuAmtFirst1dRate: '',
  });
  // 获取上新概览详情
  const handleGetNewCommodityOverview = async () => {
    const { data } = await getNewCommodityOverview({
      storeId: storeId.value,
      channelId: channelId.value,
      startDate: date.value[0] ? datefuns.formatTime(date.value[0], 'YYYYMMDD') : '',
      endDate: date.value[1] ? datefuns.formatTime(date.value[1], 'YYYYMMDD') : '',
      categoryClass2: category.value,
    });
    [newCommodityAnalysisOverviewData.value] = data;
  };
  return { newCommodityAnalysisOverviewData, handleGetNewCommodityOverview };
}
