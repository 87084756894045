import { ref, onMounted, onUnmounted } from 'vue';

export default function useTableHeight() {
  const height = ref(400);
  const computeHeight = () => {
    const { clientHeight } = document.body;
    const logo = document.getElementsByClassName('logo')[0];
    const menuHeight = 40;
    const cardHeadHeight = 56;
    const padding = 24 * 3 + 12;
    const paginationHeight = 72;
    const h = clientHeight - cardHeadHeight - menuHeight - logo.clientHeight - padding - paginationHeight;
    height.value = h < 300 ? 300 : h;
  };
  onMounted(() => {
    computeHeight();
    window.addEventListener('resize', computeHeight);
  });
  onUnmounted(() => {
    window.removeEventListener('resize', computeHeight);
  });
  return { height };
}
