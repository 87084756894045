
import { defineComponent, computed, PropType, ref, watch, nextTick } from 'vue';
import {
  INewCommodityDataOverview,
  INewCommodityTrend,
  INewCommodityCategoryAnalysis,
  INewCommodityPriceAnalysis,
  INewCommodityItem,
} from '@/modules/information-manage/shop-manage/api/type.d';
import { renderColumns } from './composables/tableData';
import { TableColumn } from './type.d';
import Card from '../card/index.vue';
import DataItem from '../data-item/index.vue';
import LineChart from './components/line-chart.vue';
import PieChart from './components/pie-chart.vue';
import ColumnDialog from './components/column-dialog.vue';
import { ElTable } from 'element-plus';
import { objectfuns, datefuns } from 'cx-utils';

export default defineComponent({
  name: 'newCommodityAnalysis',
  emits: [
    'update:date',
    'update:category',
    'update:pageParams',
    'on-show-log',
  ],
  components: {
    Card,
    DataItem,
    LineChart,
    PieChart,
    ColumnDialog,
  },
  props: {
    height: {
      type: Number,
      default: 400,
    },
    date: {
      type: Date,
      default: new Date(),
    },
    category: {
      type: String,
      default: '',
    },
    dataOverview: {
      type: Object as PropType<INewCommodityDataOverview>,
    },
    trendData: {
      type: Array as PropType<INewCommodityTrend[]>,
      default: () => [],
    },
    categoryAnalysis: {
      type: Array as PropType<INewCommodityCategoryAnalysis[]>,
      default: () => [],
    },
    priceAnalysis: {
      type: Array as PropType<INewCommodityPriceAnalysis[]>,
      default: () => [],
    },
    pageParams: {
      type: Object,
      default: () => {
        return {
          pageNum: 1,
          pageSize: 10,
          total: 0,
        };
      },
    },
    tableData: {
      type: Array as PropType<INewCommodityItem[]>,
      default: () => [],
    },
    categoryOptions: {
      type: Array as PropType<{ label: string; value: string; }[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const shortcuts = [
      {
        text: '当天',
        value: [new Date(), new Date()],
      },
      {
        text: '当前月',
        value: () => {
          const end = new Date();
          end.setMonth(end.getMonth() + 1);
          end.setDate(0);
          const start = new Date();
          start.setDate(1);
          return [start, end];
        },
      },
    ];
    const handleCategoryChange = (val: string) => {
      emit('update:category', val);
    };
    const handleDateChange = (val: string[]) => {
      emit('update:date', val);
    };
    // 数据概览
    const dataOverviewList = computed(() => {
      const list = [
        {
          label: '上新商品数',
          value: '0',
          rate: '0',
          description: (val: string, color: string) => {
            return `<div>占店铺商品数 <span style="color: ${color}">${val}%</span></div>`;
          },
          blockColor: '#52CCC8',
          tips: '上新的商品数量。',
          valueKey: 'itmShelfSpuCntFirst1d',
          rateKey: 'itmShelfSpuCntFirst1dRate',
        },
        {
          label: '上新首日销量',
          value: '0',
          rate: '0',
          description: (val: string, color: string) => {
            return `<div>占店铺销量 <span style="color: ${color}">${val}%</span></div>`;
          },
          tips: '上新商品在上新当日销量之和。',
          blockColor: '#6281F5',
          valueKey: 'trdSalesSpuNumFirst1d',
          rateKey: 'trdSalesSpuNumFirst1dRate',
        },
        {
          label: '上新首日销售额 (万元)',
          value: '0',
          rate: '0',
          description: (val: string, color: string) => {
            return `<div>占店铺销售额 <span style="color: ${color}">${val}%</span></div>`;
          },
          tips: '上新商品在上新当日销售额之和。',
          blockColor: '#F56262',
          valueKey: 'trdSalesSpuAmtFirst1d',
          rateKey: 'trdSalesSpuAmtFirst1dRate',
        },
      ];
      if (props.dataOverview !== undefined) {
        const { dataOverview } = props;
        list.forEach((data) => {
          const { valueKey, rateKey } = data;
          data.value = dataOverview[valueKey as keyof INewCommodityDataOverview];
          data.rate = dataOverview[rateKey as keyof INewCommodityDataOverview];
        });
      }
      return list;
    });
    // 趋势
    const trendSeriesData = ref<string[]>([]);
    const trendXAxisData = ref<string[]>([]);
    watch(
      () => props.trendData,
      () => {
        trendXAxisData.value = [];
        trendSeriesData.value = [];
        if (props.trendData.length > 0) {
          props.trendData.forEach((data) => {
            const { ds, itmShelfSpuCntFirst1d } = data;
            trendXAxisData.value.push(ds);
            trendSeriesData.value.push(itmShelfSpuCntFirst1d);
          });
        }
      },
      { immediate: true },
    );
    // 上新品类分析
    const categoryAnalysisPieData = computed(() => {
      const list: INewCommodityCategoryAnalysis[] = objectfuns.deepClone(props.categoryAnalysis);
      return list.sort((a, b) => {
        return Number(b.itmShelfSpuCntFirst1d) - Number(a.itmShelfSpuCntFirst1d);
      }).map((data) => {
        const { itmShelfSpuCntFirst1d, categoryClass2Name } = data;
        return {
          value: itmShelfSpuCntFirst1d,
          name: categoryClass2Name,
        };
      });
    });
    // 上新价格带分析
    const priceLegendData = computed(() => {
      return props.priceAnalysis.map((data) => {
        return {
          name: data.priceRange,
        };
      }).sort((a, b) => {
        return parseInt(b.name, 10) - parseInt(a.name, 10);
      });
    });
    const priceAnalysisPieData = computed(() => {
      const list: INewCommodityPriceAnalysis[] = objectfuns.deepClone(props.priceAnalysis);
      return list.sort((a, b) => {
        return Number(b.itmShelfSpuCntFirst1d) - Number(a.itmShelfSpuCntFirst1d);
      }).map((data) => {
        const { itmShelfSpuCntFirst1d, priceRange } = data;
        return {
          value: itmShelfSpuCntFirst1d,
          name: priceRange,
        };
      });
    });
    // 上新商品清单
    const tableRef = ref<typeof ElTable | null>(null);
    const tableScrollEl = computed(() => {
      if (tableRef.value) {
        const el = tableRef.value.$el as Element;
        const scrollBody = el.getElementsByClassName('el-table__body-wrapper')[0];
        return scrollBody;
      }
      return null;
    });
    const isShowVisableDialog = ref(false);
    const tableCols = ref<TableColumn[]>([
      'orderNum',
      'product',
      'itmShelfSpuDate',
      'trdSalesSpuNnum1d',
      'trdSalesSpuNumFirst30wi',
      'trdSalesSpuNum',
      'trdSalesSpuAmtFirst30wi',
      'trdCllctSpuCntFirst1d',
      'developments',
    ]);
    const handleCurrentChange = (pageNum: number) => {
      const params = Object.assign({}, props.pageParams);
      params.pageNum = pageNum;
      emit('update:pageParams', params);
    };
    const handleShowLog = (row: INewCommodityItem) => {
      emit('on-show-log', row.spu);
    };
    const isShowTable = ref(true);
    watch(() => props.height, () => {
      isShowTable.value = false;
      nextTick(() => {
        isShowTable.value = true;
      });
    }, { immediate: true });
    const showDateValue = computed(() => {
      const date = new Date(props.date.getTime());
      const day = date.getDay();
      const startDate = new Date(date);
      startDate.setDate(startDate.getDate() - day + 1);
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 6);
      return `${datefuns.formatTime(startDate, 'YYYY-MM-DD')} - ${datefuns.formatTime(endDate, 'YYYY-MM-DD')}`;
    });
    return {
      showDateValue,
      dataOverviewList,
      trendSeriesData,
      trendXAxisData,
      categoryAnalysisPieData,
      priceAnalysisPieData,
      tableCols,
      shortcuts,
      tableRef,
      tableScrollEl,
      handleDateChange,
      handleCategoryChange,
      handleCurrentChange,
      isShowVisableDialog,
      renderColumns,
      handleShowLog,
      isShowTable,
      priceLegendData,
    };
  },
});
