
import { defineComponent, ref, onBeforeUpdate } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { TAB_ENUM } from '@/modules/information-manage/shop-manage/views/detail/constant';
import useShopDetail from './composables/use-shop-detail';
import useShopOverview from './composables/use-shop-overview';
import useNewCommodityAnalysis from './composables/use-new-commodity-analysis';
import useHotSellingAnalysis from './composables/use-hot-selling-analysis';
import useTableHeight from './composables/use-table-height';
import ShopDetail from './components/shop-detail/index.vue';
import Tabs from './components/tabs/index.vue';
import ShopOverview from './components/shop-overview/index.vue';
import newCommodityAnalysis from './components/new-commodity-analysis/index.vue';
import CategoryAnalysis from './components/category-analysis/index.vue';
import AttributeAnalysis from './components/attribute-analysis/index.vue';
import PriceAnalysis from './components/price-analysis/index.vue';
import hotSellingAnalysis from './components/hot-selling-analysis/index.vue';
import SalesSurgeAnalysis from './components/sales-surge-analysis/index.vue';
import LogDialog from './components/log-dialog/index.vue';
import { Option } from '../type.d';
import { OPTION_ENUM } from '@/modules/information-manage/shop-manage/constant';
import { getEnumOptions } from '@/modules/information-manage/shop-manage/api';
import usePermissionConfig from '@/modules/information-manage/use-permission-config';

export default defineComponent({
  name: 'ShopManageDetail',
  components: {
    ShopDetail,
    Tabs,
    ShopOverview,
    newCommodityAnalysis,
    hotSellingAnalysis,
    SalesSurgeAnalysis,
    LogDialog,
    // SalesSurgeAnalysis,
    CategoryAnalysis,
    AttributeAnalysis,
    PriceAnalysis,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const activeTab = ref(TAB_ENUM.SHOP_OVERVIEW);
    // 品类选项
    const categoryOptions = ref<Option[]>([]);
    const handleGetCategoryOptions = async () => {
      const { data } = await getEnumOptions({ dictType: OPTION_ENUM.CATEGORY_TYPE });
      categoryOptions.value = [
        ...data.map((dict) => {
          return {
            label: dict.dictValue,

            value: dict.dictCode,
          };
        }),
      ];
    };
    // 商店id
    const storeId = ref('');
    // 商店渠道
    const channelId = ref('');
    const { shopDetailData, handleGetShopDetail } = useShopDetail(storeId, channelId);
    const {
      // 商店销售概况
      shopSalesOverviewData,
      handleGetSalesOverview,
      // 商店销售趋势
      shopSalesTrendData,
      isNew,
      trendCategoryClass,
      handleGetSalesTrend,
      // 商店上下架趋势
      upDownCategoryClass,
      shopUpDownTrendData,
      handleGetUpDownTrend,
      // topN商品列表
      shopTopCategoryClass,
      shopTopData,
      shopTopOrder,
      shopTopPageParams,
      shopTopCalcuteType,
      handleGetTopNSpu,
    } = useShopOverview(storeId, channelId);
    const {
      newCommodityAnalysisDate,
      newCommodityAnalysisCategory,
      // 数据概览
      newCommodityAnalysisOverviewData,
      loadNewCommodityAnalysis,
      // 趋势
      newCommodityTrendData,
      // 品类分析
      newCommodityCategoryAnalysisData,
      // 价格带分析
      newCommodityPriceAnalysisData,
      // 上新商品清单
      newCommodityPageData,
      newCommodityPageParams,
    } = useNewCommodityAnalysis(storeId, channelId);
    const {
      hotSellingAnalysisDate,
      hotSellingAnalysisCategory,
      hotSellingAnalysisCycle,
      hotSellingAnalysisOverviewData,
      hotSellingTrendData,
      hotSellingAnalysisCategoryData,
      hotSellingAnalysisPriceData,
      hotSellingAnalysisPageData,
      hotSellingAnalysisPageParams,
      loadHotSellingAnalysis,
    } = useHotSellingAnalysis(storeId, channelId);

    const { height } = useTableHeight();
    const handleTabChange = (tab: TAB_ENUM) => {
      switch (tab) {
        case TAB_ENUM.SHOP_OVERVIEW:
          handleGetSalesOverview();
          handleGetSalesTrend();
          handleGetUpDownTrend();
          handleGetTopNSpu();
          break;
        case TAB_ENUM.NEW_COMMODITY_ANALYSIS:
          loadNewCommodityAnalysis();
          break;
        case TAB_ENUM.HOT_SELLING_ANALYSIS:
          loadHotSellingAnalysis();
          break;
        default:
          break;
      }
    };
    // 商品id
    const commodityId = ref('');
    const isShowLog = ref(false);
    const handleShowLog = (id: string) => {
      commodityId.value = id;
      isShowLog.value = true;
    };
    const handleRouteToList = () => {
      router.push({
        name: 'InformationManageShopManageList',
      });
    };
    const init = () => {
      storeId.value = route.query.storeId as string || '';
      channelId.value = route.query.channelId as string || '';
      handleGetCategoryOptions();
      handleGetShopDetail();
      handleTabChange(activeTab.value);
    };
    init();

    const permissionConfig = usePermissionConfig();
    return {
      categoryOptions,
      commodityId,
      isShowLog,
      storeId,
      channelId,
      activeTab,
      shopDetailData,
      shopSalesOverviewData,
      shopSalesTrendData,
      isNew,
      trendCategoryClass,
      upDownCategoryClass,
      shopUpDownTrendData,
      shopTopCategoryClass,
      shopTopData,
      shopTopOrder,
      shopTopPageParams,
      shopTopCalcuteType,
      newCommodityAnalysisDate,
      newCommodityAnalysisCategory,
      newCommodityAnalysisOverviewData,
      newCommodityTrendData,
      newCommodityCategoryAnalysisData,
      newCommodityPriceAnalysisData,
      newCommodityPageData,
      newCommodityPageParams,
      hotSellingAnalysisDate,
      hotSellingAnalysisCategory,
      hotSellingAnalysisOverviewData,
      hotSellingTrendData,
      hotSellingAnalysisCycle,
      hotSellingAnalysisCategoryData,
      hotSellingAnalysisPriceData,
      hotSellingAnalysisPageData,
      hotSellingAnalysisPageParams,

      height,
      handleTabChange,
      handleShowLog,
      TAB_ENUM,
      handleRouteToList,
      permissionConfig,
    };
  },
});
