import { ref, Ref, watch, computed } from 'vue';
import useOverview from './use-overview';
import useNewCommodityTrend from './use-trend';
import useCategoryAnalysis from './use-category-analysis';
import usePriceAnalysis from './use-price-analysis';
import usePage from './use-page';

export default function useNewCommodityAnalysis(storeId: Ref<string>, channelId: Ref<string>) {
  const getMonthStartEnd = () => {
    const startDay = new Date();
    startDay.setDate(1);
    const endDay = new Date(startDay);
    endDay.setMonth(endDay.getMonth() + 1);
    endDay.setDate(0);
    return [startDay, endDay];
  };
  const newCommodityAnalysisDate = ref<Date>(new Date());
  const newCommodityAnalysisCategory = ref('-99');
  const dateArray = computed(() => {
    const date = new Date(newCommodityAnalysisDate.value.getTime());
    const day = date.getDay();
    const startDay = new Date(date);
    startDay.setDate(startDay.getDate() - day + 1);
    const endDay = new Date(startDay);
    endDay.setDate(endDay.getDate() + 6);
    return [`${startDay}`, `${endDay}`];
  });
  const {
    newCommodityAnalysisOverviewData,
    handleGetNewCommodityOverview,
  } = useOverview(storeId, channelId, dateArray, newCommodityAnalysisCategory);
  const {
    newCommodityTrendData,
    handleGetNewCommodityTrend,
  } = useNewCommodityTrend(storeId, channelId, dateArray, newCommodityAnalysisCategory);
  const {
    newCommodityCategoryAnalysisData,
    handleGetNewCommodityCategoryAnalysis,
  } = useCategoryAnalysis(storeId, channelId, dateArray, newCommodityAnalysisCategory);
  const {
    newCommodityPriceAnalysisData,
    handleGetNewCommodityPriceAnalysis,
  } = usePriceAnalysis(storeId, channelId, dateArray, newCommodityAnalysisCategory);
  const {
    newCommodityPageData,
    newCommodityPageParams,
    handleGetNewCommodityPage,
  } = usePage(storeId, channelId, dateArray, newCommodityAnalysisCategory);
  const loadNewCommodityAnalysis = (pageNum?: number) => {
    handleGetNewCommodityOverview();
    handleGetNewCommodityTrend();
    handleGetNewCommodityCategoryAnalysis();
    handleGetNewCommodityPriceAnalysis();
    if (pageNum && pageNum !== newCommodityPageParams.value.pageNum) {
      // 重置页码，如果页码有发生改变，则通过hook中的watch来执行查询
      newCommodityPageParams.value.pageNum = pageNum;
    } else {
      handleGetNewCommodityPage();
    }
  };
  watch(
    newCommodityAnalysisCategory,
    () => {
      loadNewCommodityAnalysis(1);
    },
  );
  watch(
    dateArray,
    () => {
      if (dateArray.value.length > 0 && dateArray.value.every((date: any) => date)) {
        loadNewCommodityAnalysis(1);
      }
    },
  );
  return {
    newCommodityAnalysisDate,
    newCommodityAnalysisCategory,
    newCommodityAnalysisOverviewData,
    newCommodityTrendData,
    newCommodityCategoryAnalysisData,
    newCommodityPriceAnalysisData,
    newCommodityPageData,
    newCommodityPageParams,
    loadNewCommodityAnalysis,
  };
}
