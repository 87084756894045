
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DataItem',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: [String, Number],
    tips: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    // label前的颜色块
    blockColor: {
      type: String,
      default: '#4B85FA',
    },
  },
});
