// 是否是新老品枚举
export enum PRODUCTION_STATE_ENUM {
  /* 全部 */
  ALL = '-99',
  /** 新品 */
  NEW_PRODUCTS = '1',
  /** 非新品 */
  OLD_PRODUCTS = '0'
}

export const PRODUCTION_STATE_LIST = [
  { label: '全部', value: PRODUCTION_STATE_ENUM.ALL },
  { label: '新品', value: PRODUCTION_STATE_ENUM.NEW_PRODUCTS },
  { label: '非新品', value: PRODUCTION_STATE_ENUM.OLD_PRODUCTS },
];

export enum ORDER_ENUM {
  // 降序
  DESC = 'descending',
  // 升序
  ASC = 'ascending',
}

export enum DS_ORDER_ENUM {
  // 降序
  DESC = 'desc',
  // 升序
  ASC = 'asc',
}

// 获取下拉选项的枚举
export enum OPTION_ENUM {
  /* 渠道类型 */
  CHANNEL_TYPE = '1',
  /* 店铺类型 */
  SHOP_TYPE = '2',
  /* 二级分类 */
  CATEGORY_TYPE = '7',
  /* 淘品库-店铺 */
  STORE_NAME_DOMESTIC = '6',
  /* 淘品库-品牌 */
  BRAND_NAME_DOMESTIC = '4',
  /* 二级分类 */
  SECONDARY_CATEGORY = '7',
}

// 关注状态
export enum FOUCE_STATUS_ENUM {
  /* 关注 */
  FOUCE = '1',
  /* 未关注 */
  UN_FOUCE = '0',
}

// 店铺分析状态
export enum ANALYSIS_STATUS_ENUM {
  /* 已进行店铺分析 */
  ANALYSIS = '1',
  /* 未进行店铺分析 */
  UN_ANALYSIS = '0',
}
