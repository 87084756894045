import { ref, Ref } from 'vue';
import { getShopDetail } from '@/modules/information-manage/shop-manage/api';
import { IShopDetail } from '@/modules/information-manage/shop-manage/api/type.d';

export default function useShopDetail(storeId: Ref<string>, channelId: Ref<string>) {
  // 商店详情
  const shopDetailData = ref<IShopDetail>({
    storeId: '',
    storeName: '',
    storePictureLink: '',
    storeUrl: '',
    channelId: '',
    channelName: '',
    storeType: '',
    mainCategory: '',
    collectDate: '',
  });
  // 获取商店详情
  const handleGetShopDetail = async () => {
    const { data } = await getShopDetail({ storeId: storeId.value, channelId: channelId.value });
    [shopDetailData.value] = data;
  };
  return { shopDetailData, handleGetShopDetail };
}
