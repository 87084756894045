
import { defineComponent, PropType, computed, ref } from 'vue';
import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import { LabelLayout } from 'echarts/features';
import { IAnalysisData } from '../types';
import { plus } from 'number-precision';

use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
]);

export default defineComponent({
  name: 'LineChart',
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    legendName: {
      type: String,
      default: '',
    },
    seriesData: {
      type: Object as PropType<IAnalysisData>,
      default: () => ({}),
    },
  },
  components: {
    VChart,
  },
  setup(props) {
    const chartEl = ref<InstanceType<typeof VChart>>();
    const option = computed(() => {
      const {
        seriesData: {
          tooltipTitle,
          unit,
          data,
          legendData,
        },
        legendName,
        title,
      } = props;
      const baseOption = {
        title: {
          text: title,
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
          formatter: (params: any) => {
            const legend:any = chartEl.value?.getOption().legend;
            const keys:string[] = data.map(v => v.name);
            Object.entries(legend[0].selected).forEach((v) => {
              const [key, selected] = v;
              if (!selected) {
                const index = keys.findIndex(it => it === key);
                keys.splice(index, 1);
              }
            });
            const total = keys.reduce((count, current) => {
              return plus(count, data.find(it => it.name === current)!.value);
            }, 0);
            let percent = 0;
            if (total !== 0) {
              percent = Math.round((params.value * 10000) / total) / 100;
            }
            return `${params.data.name}<br/>${tooltipTitle}：${params.value}${unit} (${percent}%)`;
          },
        },
        label: {
          formatter: (params: any) => {
            const legend:any = chartEl.value?.getOption().legend;
            const keys:string[] = data.map(v => v.name);
            Object.entries(legend[0].selected).forEach((v) => {
              const [key, selected] = v;
              if (!selected) {
                const index = keys.findIndex(it => it === key);
                keys.splice(index, 1);
              }
            });
            const total = keys.reduce((count, current) => {
              return plus(count, data.find(it => it.name === current)!.value);
            }, 0);
            let percent = 0;
            if (total !== 0) {
              percent = Math.round((params.value * 10000) / total) / 100;
            }
            return `${params.data.name}：${params.value}${unit}(${percent}%)`;
          },
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: legendData,
        },
        series: [
          {
            name: legendName,
            data,
            type: 'pie',
            radius: ['50%', '80%'],
            left: '250px',
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };
      return props.options ? Object.assign(baseOption, props.options) : baseOption;
    });
    const selectAllLegend = () => {
      const {
        seriesData: {
          data,
          legendData = data,
        },
      } = props;
      legendData.forEach((v) => {
        chartEl.value?.dispatchAction({
          type: 'legendSelect',
          name: v.name,
        });
      });
    };
    return {
      selectAllLegend,
      option,
      chartEl,
    };
  },
  render() {
    return (
      <div class="chart_layout">
        {this.seriesData.data.length === 0 ? (
          <empty content="暂无数据" />
        ) : (
          <v-chart ref="chartEl" class="pie-chart" option={this.option} autoresize={true} />
        )}
      </div>
    );
  },
});
