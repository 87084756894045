
import { defineComponent, PropType, computed } from 'vue';
import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import { LabelLayout } from 'echarts/features';

use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
]);

export default defineComponent({
  name: 'PieChart',
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    legendName: {
      type: String,
      default: '',
    },
    legendData: {
      type: Array,
      default: () => [],
    },
    seriesData: {
      type: Array as PropType<{ value: string | number; name: string; }[]>,
      default: () => [],
    },
  },
  components: {
    VChart,
  },
  setup(props) {
    const option = computed(() => {
      const baseOption = {
        title: {
          text: props.title,
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
          formatter: (params: any) => {
            return `${params.marker}${params.name} ${params.value}，${params.percent}%`;
          },
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: props.legendData.length > 0
            ? props.legendData
            : props.seriesData.map(data => data.name),
        },
        series: [
          {
            name: props.legendName,
            data: props.seriesData,
            type: 'pie',
            radius: '80%',
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };
      return props.options ? Object.assign(baseOption, props.options) : baseOption;
    });
    return {
      option,
    };
  },
  render() {
    return (
      <div class="chart-wrapper">
        {
          this.seriesData.length === 0
            ? <empty content="暂无数据" />
            : <v-chart class="pie-chart" option={this.option} autoresize={true} />
        }
      </div>
    );
  },
});
