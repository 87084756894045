import { ref, Ref, watch } from 'vue';
import { getShopUpDownTrend } from '@/modules/information-manage/shop-manage/api';
import { IShopUpDownTrend } from '@/modules/information-manage/shop-manage/api/type.d';

export default function useUpDownTrend(storeId: Ref<string>, channelId: Ref<string>) {
  // 商店上下架趋势
  const shopUpDownTrendData = ref<IShopUpDownTrend[]>([]);
  // 品类
  const upDownCategoryClass = ref('-99');
  // 获取商店上下架趋势
  const handleGetUpDownTrend = async () => {
    const { data } = await getShopUpDownTrend({
      storeId: storeId.value,
      channelId: channelId.value,
      categoryClass2: upDownCategoryClass.value,
    });
    shopUpDownTrendData.value = data;
  };
  watch(
    upDownCategoryClass,
    () => {
      handleGetUpDownTrend();
    },
  );
  return {
    upDownCategoryClass,
    shopUpDownTrendData,
    handleGetUpDownTrend,
  };
}
