
import { defineComponent, ref, PropType, watch } from 'vue';
import { ElMessage } from 'element-plus';
import IconClear from '@/assets/images/icon_clear_all.png';
import { TableColumn } from '../type.d';

export default defineComponent({
  components: {
  },
  emits: ['update:visible', 'confirm', 'update:columns'],
  props: {
    columns: {
      type: Array as PropType<TableColumn[]>,
      required: true,
    },
    visible: {
      type: Boolean,
      defalut: false,
    },
  },
  setup(props, { emit }) {
    const columnSelectionList = ref<TableColumn[]>([]);
    const columnList = [
      { label: '序号', value: 'orderNum' },
      { label: '商品', value: 'product' },
      { label: '首次上架时间', value: 'itmShelfSpuDate' },
      { label: '首日销量', value: 'trdSalesSpuNnum1d' },
      { label: '上新30天销量', value: 'trdSalesSpuNumFirst30wi' },
      { label: '总销量', value: 'trdSalesSpuNum' },
      { label: '上新30天销售额(万元)', value: 'trdSalesSpuAmtFirst30wi' },
      { label: '首日收藏量', value: 'trdCllctSpuCntFirst1d' },
      { label: '最近上下架动态', value: 'developments' },
      { label: '最近上架时间', value: 'itmLatelyShelfSpuDate' },
      { label: '最近下架时间', value: 'itmLatelyOffSpuDate' },
      { label: '总评价', value: 'srvcCommentCnt' },
    ];
    watch(
      props.columns,
      () => {
        columnSelectionList.value = props.columns;
      },
    );
    const handleOpen = () => {
      columnSelectionList.value = props.columns;
    };
    const handleClose = async () => {
      emit('update:visible', false);
    };
    const handleConfirm = async () => {
      if (columnSelectionList.value.length === 0) {
        ElMessage.error('至少需要选择1个筛选');
        return;
      }
      emit('confirm', columnSelectionList.value);
      emit('update:columns', columnSelectionList.value);
      handleClose();
    };
    const handleReset = async () => {
      columnSelectionList.value = [];
    };
    return {
      handleReset,
      handleClose,
      handleConfirm,
      handleOpen,
      columnList,
      columnSelectionList,
    };
  },
  render() {
    return (
      <el-dialog
        title="更改指标"
        modelValue={this.visible}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        center
        close-on-click-modal={false}
        close-on-press-escape={false}
        show-close={false}
        width="650px"
        custom-class="el-dialog-inner-scroll"
        v-slots={{
          title: () => (
            <el-row type="flex" justify="space-between">
              <div class="el-dialog__title">更改指标</div>
              <div class="clear_all" onClick={this.handleReset}>
                <img src={IconClear} alt="" class="icon" />
                <div>清空全部</div>
              </div>
            </el-row>
          ),
        }}
      >
        <div
          class="search-setting-container"
        >
          <el-checkbox-group
            v-model={this.columnSelectionList}
          >
            {
              this.columnList.map(col => <el-checkbox border label={col.value}>{col.label}</el-checkbox>)
            }
          </el-checkbox-group>
        </div>
        <el-row type="flex" align-items="center" justify="space-between" class="footer">
          <div class="right">
            <el-button size="small" onClick={this.handleClose}>取消</el-button>
            <el-button
              type="primary"
              size="small"
              onClick={this.handleConfirm}
            >确定</el-button>
          </div>
        </el-row>
      </el-dialog>
    );
  },
});
