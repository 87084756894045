import { ref, computed, watch } from 'vue';
import {
  getPriceTrendList,
  getPriceDistributionList,
  getPriceDistributionOverviewList,
} from '@/modules/information-manage/shop-manage/api';
import {
  IStorePriceDistributionOverviewListReq,
  IStorePriceDistributionOverviewListRes,
  IStorePriceDistributionListRes,
} from '@/modules/information-manage/shop-manage/api/type.d';
import { useRoute } from 'vue-router';
import {
  DATE_ENUM,
  SALES_TREND_ENUM,
  ALL,
} from '../constant';
import {
  IHotDataMap,
  IDistributeDataMap,
  IDataItem,
} from '../types';
import dayjs from 'dayjs';
import {
  DS_ORDER_ENUM,
} from '@/modules/information-manage/shop-manage/constant';
import { ElMessage } from 'element-plus';
import { plus } from 'number-precision';
import { useList } from '@/hooks/use-list';

export const useData = (
  reSelectLegend: () => void,
) => {
  const route = useRoute();
  const storeId = route.query.storeId as string;
  const channelId = route.query.channelId as string;
  const params = ref({
    date: DATE_ENUM.SEVEN_DAY,
  });
  const selectedCategory = ref<string>(ALL);
  // 当前筛选的时期区间
  const currentDate = computed(() => {
    switch (params.value.date) {
      case DATE_ENUM.SEVEN_DAY:
        return {
          startDate: dayjs().add(-7, 'day').format('YYYYMMDD'),
          endDate: dayjs().add(-1, 'day').format('YYYYMMDD'),
        };
      case DATE_ENUM.THIRTY_DAY:
        return {
          startDate: dayjs().add(-30, 'day').format('YYYYMMDD'),
          endDate: dayjs().add(-1, 'day').format('YYYYMMDD'),
        };
      default:
        return {
          startDate: '',
          endDate: '',
        };
    }
  });
  // 当前选中的价格范围
  const hotSelectKeys = ref<string[]>([]);
  // 价格范围
  const hotDataMap = ref<IHotDataMap>({
    [SALES_TREND_ENUM.SALES_VOLUME]: {
      xAxisName: '',
      yAxisName: '销量（件）',
      filterLabelName: '价格带',
      filterValueName: '总销量(件)',
      xData: [],
      options: [],
      series: [],
    },
    [SALES_TREND_ENUM.VALUE_OF_SALES]: {
      xAxisName: '',
      yAxisName: '销售额（万元）',
      filterLabelName: '价格带',
      filterValueName: '总销售额(万元)',
      xData: [],
      options: [],
      series: [],
    },
    [SALES_TREND_ENUM.NEW]: {
      xAxisName: '',
      yAxisName: '上新商品数（个）',
      filterLabelName: '价格带',
      filterValueName: '上新商品总数(个)',
      xData: [],
      options: [],
      series: [],
    },
  });
  // 价格带tab
  const hotParams = ref({
    type: SALES_TREND_ENUM.SALES_VOLUME,
  });

  // 当前展示的热销品类数据
  const currendHotData = computed(() => {
    return hotDataMap.value[hotParams.value.type];
  });
  // 格式化数据 将小于0的数据改为0
  const formatNum = (count: string|number) => {
    const val = Number(count);
    if (val < 0) return 0;
    return val;
  };
  // 获取价格带趋势数据
  const getHotInfo = async () => {
    const { data } = await getPriceTrendList({
      storeId,
      channelId,
      ...currentDate.value,
      categoryClass2: selectedCategory.value,
    });
    const cates = [...new Set(data.map(v => v.priceRange))];
    // 生成当前日期区间的日期列表 避免后端给到的日期区间顺序有问题或者缺少日期
    const xData:{
      key: string;
      label: string;
    }[] = [];
    let curDate = currentDate.value.startDate;
    while (true) {
      xData.push({
        key: dayjs(curDate).format('YYYYMMDD'),
        label: dayjs(curDate).format('YYYY-MM-DD'),
      });
      if (curDate === currentDate.value.endDate) break;
      curDate = dayjs(curDate).add(1, 'day').format('YYYYMMDD');
    }
    // 组装销量数据
    hotDataMap.value[SALES_TREND_ENUM.SALES_VOLUME].options = cates.map(v => ({
      name: v,
      count: data
        .filter(item => item.priceRange === v)
        .reduce((total, current) => plus(formatNum(current.trdSalesSpuNum1d), total), 0),
    })).sort((a, b) => b.count - a.count);
    hotDataMap.value[SALES_TREND_ENUM.SALES_VOLUME].xData = xData.map(v => v.label);
    hotDataMap.value[SALES_TREND_ENUM.SALES_VOLUME].series = cates.map(v => ({
      name: v,
      data: xData.map((xItem) => {
        const row = data.find(item => item.priceRange === v && xItem.key === item.ds);
        if (row) {
          return formatNum(row.trdSalesSpuNum1d);
        }
        return 0;
      }),
    }));
    // 组装销售额数据
    hotDataMap.value[SALES_TREND_ENUM.VALUE_OF_SALES].options = cates.map(v => ({
      name: v,
      count: data
        .filter(item => item.priceRange === v)
        .reduce((total, current) => plus(formatNum(current.trdSalesSpuAmt1d), total), 0),
    })).sort((a, b) => b.count - a.count);
    hotDataMap.value[SALES_TREND_ENUM.VALUE_OF_SALES].xData = xData.map(v => v.label);
    hotDataMap.value[SALES_TREND_ENUM.VALUE_OF_SALES].series = cates.map((v) => {
      return {
        name: v,
        data: xData.map((xItem) => {
          const row = data.find((item) => {
            return item.priceRange === v && xItem.key === item.ds;
          });
          if (row) {
            return formatNum(row.trdSalesSpuAmt1d);
          }
          return 0;
        }),
      };
    });
    // 组装上新商品数数据
    hotDataMap.value[SALES_TREND_ENUM.NEW].options = cates.map(v => ({
      name: v,
      count: data
        .filter(item => item.priceRange === v)
        .reduce((total, current) => plus(formatNum(current.itmShelfSpuCntFirst1d), total), 0),
    })).sort((a, b) => b.count - a.count);
    hotDataMap.value[SALES_TREND_ENUM.NEW].xData = xData.map(v => v.label);
    hotDataMap.value[SALES_TREND_ENUM.NEW].series = cates.map(v => ({
      name: v,
      data: xData.map((xItem) => {
        const row = data.find(item => item.priceRange === v && xItem.key === item.ds);
        if (row) {
          return formatNum(row.itmShelfSpuCntFirst1d);
        }
        return 0;
      }),
    }));
    // 假如当前选中的分类为空 默认选中5个
    // if (hotSelectKeys.value.length === 0) {
    hotSelectKeys.value = currendHotData.value.options.map(v => v.name).slice(0, 5);
    // }
    // 暂存，交互需求变更
    // else {
    //   hotSelectKeys.value = currendHotData.value.options
    //     .map(v => v.name)
    //     .filter(name => hotSelectKeys.value.includes(name));
    // }
  };
  // 切换热销品类tab 同时选中品类排名前5项
  const handleChangeHotTab = () => {
    hotSelectKeys.value = currendHotData.value.options.map(v => v.name).slice(0, 5);
  };
  // 选择价格范围
  const handleChangeHotSelectKeys = (keys: string[]) => {
    if (keys.length > 5) {
      ElMessage.error('最多选择5项进行对比');
      return;
    }
    if (keys.length === 0) {
      ElMessage.error('最少选择1项进行对比');
      return;
    }
    hotSelectKeys.value = keys;
  };
  // 品类分布参数
  const distributionParams = ref({
    type: SALES_TREND_ENUM.SALES_VOLUME,
  });
  // 品类分布数据
  const distributeDataMap = ref<IDistributeDataMap>({
    [SALES_TREND_ENUM.SALES_VOLUME]: {
      tooltipTitle: '销量占比',
      unit: '件',
      legendData: [],
      data: [],
    },
    [SALES_TREND_ENUM.VALUE_OF_SALES]: {
      tooltipTitle: '销售额占比',
      unit: '万元',
      legendData: [],
      data: [],
    },
    [SALES_TREND_ENUM.NEW]: {
      tooltipTitle: '上新占比',
      unit: '个',
      legendData: [],
      data: [],
    },
  });
  // 当前价格范围数据
  const currendDistributeData = computed(() => {
    return distributeDataMap.value[distributionParams.value.type];
  });
  // 价格带多选的排序算法
  const rangeSort = (list: IDataItem[]) => {
    // store = [闭区间范围, 开区间范围（以上｜以下）]
    const store:Array<IDataItem[]> = [[], []];
    list.forEach((item) => {
      if (item.name.indexOf('-') === -1) {
        store[1].push(item);
      } else {
        store[0].push(item);
      }
    });
    store[0].sort((a, b) => {
      return Number(a.name.split('-')[0]) - Number(b.name.split('-')[0]);
    });
    store[1].sort((a, b) => {
      return parseFloat(a.name) - parseFloat(b.name);
    });
    return [...store[0], ...store[1]];
  };

  // 价格带分布单tab数据整理
  const priceDataSortByTab = (data:IStorePriceDistributionListRes[], tabKey: SALES_TREND_ENUM) => {
    const tabData = data.map(v => ({
      name: v.priceRange,
      value: formatNum(v[tabKey]),
    }));
    distributeDataMap.value[tabKey].data = tabData.sort((a, b) => b.value - a.value);
    distributeDataMap.value[tabKey].legendData = rangeSort(tabData).reverse();
  };

  // 获取价格带分布数据
  const getDistributionData = async () => {
    const { data } = await getPriceDistributionList({
      storeId,
      channelId,
      ...currentDate.value,
      categoryClass2: selectedCategory.value,
    });

    priceDataSortByTab(data, SALES_TREND_ENUM.SALES_VOLUME);
    priceDataSortByTab(data, SALES_TREND_ENUM.VALUE_OF_SALES);
    priceDataSortByTab(data, SALES_TREND_ENUM.NEW);
  };

  // 价格带分布概览参数
  const overViewParams = ref({
    orderColumn: SALES_TREND_ENUM.SALES_VOLUME,
    orderType: DS_ORDER_ENUM.DESC,
  });
  const {
    params: overViewPages,
    tableData: overViewData,
    handleSearch: handleSearchOverViewData,
    tableTotal: overViewTotal,
    handleCurrentChange: handleOverViewCurrentChange,
  } = useList<IStorePriceDistributionOverviewListRes['rows'][0], IStorePriceDistributionOverviewListReq>({
    request: {
      api: getPriceDistributionOverviewList,
      params: {
        pageNum: 1,
        pageSize: 10,
        categoryClass2: selectedCategory.value,
      },
      handleParams(customParams) {
        return {
          ...customParams,
          storeId,
          channelId,
          ...currentDate.value,
          ...overViewParams.value,
        };
      },
    },
    response: {
      tableDataKey: 'data.rows',
      totalKey: 'data.totalNum',
    },
  });

  // 获取数据
  const getData = () => {
    getHotInfo();
    getDistributionData();
    handleSearchOverViewData();
  };
  // 改变日期
  const handleChangeDate = (val: DATE_ENUM) => {
    params.value.date = val;
    hotSelectKeys.value = [];
    getData();
    reSelectLegend();
  };
  // 改变价格带分布概览排序
  const handleChangeSort = (val: SALES_TREND_ENUM) => {
    if (val === overViewParams.value.orderColumn) {
      overViewParams.value.orderType = Object.values(DS_ORDER_ENUM)
        .find(v => v !== overViewParams.value.orderType)!;
    } else {
      overViewParams.value.orderType = DS_ORDER_ENUM.DESC;
      overViewParams.value.orderColumn = val;
    }
    handleSearchOverViewData();
  };
  // 初始化
  const init = () => {
    getData();
  };
  init();

  watch(selectedCategory, () => {
    overViewPages.value.categoryClass2 = selectedCategory.value;
    getData();
  });

  return {
    hotParams,
    distributionParams,
    overViewParams,
    params,
    handleChangeDate,
    handleChangeSort,
    currendHotData,
    currendDistributeData,
    hotSelectKeys,
    handleChangeHotSelectKeys,
    handleChangeHotTab,
    overViewPages,
    overViewTotal,
    overViewData,
    handleOverViewCurrentChange,
    selectedCategory,
  };
};
