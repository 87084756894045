import { ref, Ref, watch } from 'vue';
import { getSalesTrend } from '@/modules/information-manage/shop-manage/api';
import { IShopSalesTrend } from '@/modules/information-manage/shop-manage/api/type.d';
import { PRODUCTION_STATE_ENUM } from '@/modules/information-manage/shop-manage/constant';

export default function useSalesTrend(storeId: Ref<string>, channelId: Ref<string>) {
  // 商店销售趋势
  const shopSalesTrendData = ref<IShopSalesTrend[]>([]);
  // 品类
  const trendCategoryClass = ref('-99');
  // 是否新老品
  const isNew = ref<PRODUCTION_STATE_ENUM>(PRODUCTION_STATE_ENUM.ALL);
  // 获取商店销售趋势
  const handleGetSalesTrend = async () => {
    const { data } = await getSalesTrend({
      storeId: storeId.value,
      channelId: channelId.value,
      categoryClass2: trendCategoryClass.value,
      isNew: isNew.value,
    });
    shopSalesTrendData.value = data;
  };
  watch(
    trendCategoryClass,
    () => {
      handleGetSalesTrend();
    },
  );
  watch(
    isNew,
    () => {
      handleGetSalesTrend();
    },
  );
  return {
    trendCategoryClass,
    isNew,
    shopSalesTrendData,
    handleGetSalesTrend,
  };
}
