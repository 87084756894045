import { ref } from 'vue';
import { getEnumOptions } from '@/modules/information-manage/shop-manage/api';
import { OPTION_ENUM } from '../constant';

export const useCategoryOptions = () => {
  const categoryOptions = ref<{label:string; value: string;}[]>([]);

  const getCategoryOptions = async () => {
    const { data } = await getEnumOptions({ dictType: OPTION_ENUM.CATEGORY_TYPE });
    categoryOptions.value = [
      ...data.map((dict) => {
        return {
          label: dict.dictValue,
          value: dict.dictCode,
        };
      }),
    ];
    return categoryOptions.value;
  };

  return {
    getCategoryOptions,
  };
};
