
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'Menu',
  emits: ['update:modelValue', 'change'],
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    tabList: {
      type: Array as PropType<{ label: string; value: string; }[]>,
      default: () => [],
    },
    defaultValue: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const handleClick = (value: string) => {
      if (props.modelValue !== value) {
        emit('update:modelValue', value);
        emit('change', value);
      }
    };
    return {
      handleClick,
    };
  },
  render() {
    const renderItem = (item: { label: string; value: string; }) => {
      return (
        <div
          class={`item ${this.modelValue === item.value ? 'selected' : ''}`}
          onClick={() => this.handleClick(item.value)}
        >
          { item.label }
        </div>
      );
    };
    return (
      <div class="flex menu-wrapper">
        { this.tabList.map(item => renderItem(item)) }
      </div>
    );
  },
});
