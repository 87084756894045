import { ref, computed, ComputedRef } from 'vue';
import {
  getAttributeTrendData,
  getAttributeOverViewData,
  getAttributeDictOptions,
  getAttributeDistributionData,
} from '@/modules/information-manage/shop-manage/api';
import {
  IGetAttributeOverViewDataReq,
  IGetAttributeOverViewDataRes,
} from '@/modules/information-manage/shop-manage/api/type.d';
import { useRoute } from 'vue-router';
import {
  DATE_ENUM,
  SALES_TREND_ENUM,
} from '../constant';
import dayjs from 'dayjs';
import {
  DS_ORDER_ENUM,
} from '@/modules/information-manage/shop-manage/constant';
import { useList } from '@/hooks/use-list';
import {
  ITrendDataMap,
  IDistributeDataMap,
  IGlobalParams,
  IAttrOptionsItem,
} from '../types';
import { ElMessage } from 'element-plus';
import { plus } from 'number-precision';

export const useData = (cates: ComputedRef<{
  value: string;
  label: string;
}[]>) => {
  const route = useRoute();
  const storeId = route.query.storeId as string;
  const channelId = route.query.channelId as string;
  // 全局参数
  const params = ref<IGlobalParams>({
    date: DATE_ENUM.SEVEN_DAY,
    categoryClass2: '',
    itmAttr: '',
  });
  // 全部属性下拉选项
  const attrOptions = ref<Array<IAttrOptionsItem>>([]);
  // 获取属性下拉选项
  const getAttrDict = async () => {
    const { data } = await getAttributeDictOptions({
      categoryClass2: params.value.categoryClass2,
    });
    attrOptions.value = data.map(v => ({
      label: v.itmAttr,
      value: v.itmAttr,
      categoryClass2: v.categoryClass2,
    }));
  };
  // 当前筛选的时期区间
  const currentDate = computed(() => {
    switch (params.value.date) {
      case DATE_ENUM.SEVEN_DAY:
        return {
          startDate: dayjs().add(-7, 'day').format('YYYYMMDD'),
          endDate: dayjs().add(-1, 'day').format('YYYYMMDD'),
        };
      case DATE_ENUM.THIRTY_DAY:
        return {
          startDate: dayjs().add(-30, 'day').format('YYYYMMDD'),
          endDate: dayjs().add(-1, 'day').format('YYYYMMDD'),
        };
      default:
        return {
          startDate: '',
          endDate: '',
        };
    }
  });
  // 属性值趋势参数
  const trendParams = ref({
    type: SALES_TREND_ENUM.SALES_VOLUME,
  });
  // 当前选中的属性值
  const trendSelectKeys = ref<string[]>([]);
  // 属性值趋势数据
  const trendDataMap = ref<ITrendDataMap>({
    [SALES_TREND_ENUM.SALES_VOLUME]: {
      xAxisName: '',
      yAxisName: '销量（件）',
      filterLabelName: '属性值',
      filterValueName: '总销量(件)',
      options: [],
      xData: [],
      series: [],
    },
    [SALES_TREND_ENUM.VALUE_OF_SALES]: {
      xAxisName: '',
      yAxisName: '销售额（万元）',
      filterLabelName: '属性值',
      filterValueName: '总销售额(万元)',
      options: [],
      xData: [],
      series: [],
    },
    [SALES_TREND_ENUM.NEW]: {
      xAxisName: '',
      yAxisName: '上新商品数（个）',
      filterLabelName: '属性值',
      filterValueName: '上新商品总数(个)',
      options: [],
      xData: [],
      series: [],
    },
  });
  // 当前选中的属性值趋势数据
  const currendTrendData = computed(() => {
    return trendDataMap.value[trendParams.value.type];
  });
  // 格式化数据 将小于0的数据改为0
  const formatNum = (count: string|number) => {
    const val = Number(count);
    if (val < 0) return 0;
    return val;
  };
  // 获取属性值趋势数据
  const getTrendData = async () => {
    const { data } = await getAttributeTrendData({
      storeId,
      channelId,
      ...currentDate.value,
      categoryClass2: params.value.categoryClass2,
      itmAttr: params.value.itmAttr,
    });
    // 获取所有的属性
    const attrs = [...new Set(data.map(v => v.itmAttrVal))];
    // 生成当前日期区间的日期列表 避免后端给到的日期区间顺序有问题或者缺少日期
    const xData:{
      key: string;
      label: string;
    }[] = [];
    let curDate = currentDate.value.startDate;
    while (true) {
      xData.push({
        key: dayjs(curDate).format('YYYYMMDD'),
        label: dayjs(curDate).format('YYYY-MM-DD'),
      });
      if (curDate === currentDate.value.endDate) break;
      curDate = dayjs(curDate).add(1, 'day').format('YYYYMMDD');
    }
    // 组装销量数据
    trendDataMap.value[SALES_TREND_ENUM.SALES_VOLUME].options = attrs.map(v => ({
      name: v,
      count: data
        .filter(item => item.itmAttrVal === v)
        .reduce((total, current) => plus(formatNum(current.trdSalesSpuNum), total), 0),
    })).sort((a, b) => b.count - a.count);
    trendDataMap.value[SALES_TREND_ENUM.SALES_VOLUME].xData = xData.map(v => v.label);
    trendDataMap.value[SALES_TREND_ENUM.SALES_VOLUME].series = attrs.map(v => ({
      name: v,
      data: xData.map((xItem) => {
        const row = data.find(item => item.itmAttrVal === v && xItem.key === String(item.ds));
        if (row) {
          return formatNum(row.trdSalesSpuNum);
        }
        return 0;
      }),
    }));
    // 组装销售额数据
    trendDataMap.value[SALES_TREND_ENUM.VALUE_OF_SALES].options = attrs.map(v => ({
      name: v,
      count: data
        .filter(item => item.itmAttrVal === v)
        .reduce((total, current) => plus(formatNum(current.trdSalesSpuAmt), total), 0),
    })).sort((a, b) => b.count - a.count);
    trendDataMap.value[SALES_TREND_ENUM.VALUE_OF_SALES].xData = xData.map(v => v.label);
    trendDataMap.value[SALES_TREND_ENUM.VALUE_OF_SALES].series = attrs.map(v => ({
      name: v,
      data: xData.map((xItem) => {
        const row = data.find(item => item.itmAttrVal === v && xItem.key === String(item.ds));
        if (row) {
          return formatNum(row.trdSalesSpuAmt);
        }
        return 0;
      }),
    }));
    // 组装上新商品数数据
    trendDataMap.value[SALES_TREND_ENUM.NEW].options = attrs.map(v => ({
      name: v,
      count: data
        .filter(item => item.itmAttrVal === v)
        .reduce((total, current) => plus(formatNum(current.itmShelfSpuCntFirst), total), 0),
    })).sort((a, b) => b.count - a.count);
    trendDataMap.value[SALES_TREND_ENUM.NEW].xData = xData.map(v => v.label);
    trendDataMap.value[SALES_TREND_ENUM.NEW].series = attrs.map(v => ({
      name: v,
      data: xData.map((xItem) => {
        const row = data.find(item => item.itmAttrVal === v && xItem.key === String(item.ds));
        if (row) {
          return formatNum(row.itmShelfSpuCntFirst);
        }
        return 0;
      }),
    }));
    // 假如当前选中的属性为空 默认选中5个
    if (trendSelectKeys.value.length === 0) {
      trendSelectKeys.value = currendTrendData.value.options.map(v => v.name).slice(0, 5);
    }
  };
  // 切换属性值趋势tab 同时选中属性排名前5项
  const handleChangeTrendTab = () => {
    trendSelectKeys.value = currendTrendData.value.options.map(v => v.name).slice(0, 5);
  };
  // 选择属性值
  const handleChangeTrendSelectKeys = (keys: string[]) => {
    if (keys.length > 5) {
      ElMessage.error('最多选择5项进行对比');
      return;
    }
    if (keys.length === 0) {
      ElMessage.error('最少选择1项进行对比');
      return;
    }
    trendSelectKeys.value = keys;
  };
  // 属性值分布参数
  const distributionParams = ref({
    type: SALES_TREND_ENUM.SALES_VOLUME,
  });
  // 属性值分布数据
  const distributeDataMap = ref<IDistributeDataMap>({
    [SALES_TREND_ENUM.SALES_VOLUME]: {
      tooltipTitle: '销量占比',
      unit: '件',
      data: [],
    },
    [SALES_TREND_ENUM.VALUE_OF_SALES]: {
      tooltipTitle: '销售额占比',
      unit: '万元',
      data: [],
    },
    [SALES_TREND_ENUM.NEW]: {
      tooltipTitle: '上新占比',
      unit: '个',
      data: [],
    },
  });
  // 当前选择的属性值分布数据
  const currendDistributeData = computed(() => {
    return distributeDataMap.value[distributionParams.value.type];
  });
  // 获取属性值分布数据
  const getDistributionData = async () => {
    const { data } = await getAttributeDistributionData({
      storeId,
      channelId,
      ...currentDate.value,
      ...params.value,
    });
    distributeDataMap.value[SALES_TREND_ENUM.SALES_VOLUME].data = data.map(v => ({
      name: v.itmAttrVal,
      value: formatNum(v.trdSalesSpuNum),
    })).sort((a, b) => b.value - a.value);
    distributeDataMap.value[SALES_TREND_ENUM.VALUE_OF_SALES].data = data.map(v => ({
      name: v.itmAttrVal,
      value: formatNum(v.trdSalesSpuAmt),
    })).sort((a, b) => b.value - a.value);
    distributeDataMap.value[SALES_TREND_ENUM.NEW].data = data.map(v => ({
      name: v.itmAttrVal,
      value: formatNum(v.itmShelfSpuCntFirst),
    })).sort((a, b) => b.value - a.value);
  };
  // 属性值概览参数
  const overViewParams = ref({
    orderColumn: SALES_TREND_ENUM.SALES_VOLUME,
    orderType: DS_ORDER_ENUM.DESC,
  });
  const {
    params: overViewPages,
    tableData: overViewData,
    handleSearch: handleSearchOverViewData,
    tableTotal: overViewTotal,
    handleCurrentChange: handleOverViewCurrentChange,
  } = useList<IGetAttributeOverViewDataRes, IGetAttributeOverViewDataReq>({
    request: {
      api: getAttributeOverViewData,
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      handleParams(customParams) {
        return {
          ...customParams,
          storeId,
          channelId,
          ...currentDate.value,
          ...params.value,
          ...overViewParams.value,
        };
      },
    },
    response: {
      tableDataKey: 'data.rows',
      totalKey: 'data.totalNum',
    },
  });
  // 获取数据
  const getData = () => {
    trendSelectKeys.value = [];
    getTrendData();
    getDistributionData();
    handleSearchOverViewData();
  };
  // 改变日期
  const handleChangeDate = (val: DATE_ENUM) => {
    params.value.date = val;
    trendSelectKeys.value = [];
    getData();
  };
  // 改变排序
  const handleChangeSort = (val: SALES_TREND_ENUM) => {
    if (val === overViewParams.value.orderColumn) {
      overViewParams.value.orderType = Object.values(DS_ORDER_ENUM)
        .find(v => v !== overViewParams.value.orderType)!;
    } else {
      overViewParams.value.orderType = DS_ORDER_ENUM.DESC;
      overViewParams.value.orderColumn = val;
    }
    handleSearchOverViewData();
  };
  // 改变分类 同时需要选中对应的第一个属性
  const handleChangeCate = async () => {
    await getAttrDict();
    params.value.itmAttr = attrOptions.value[0].value;
    trendSelectKeys.value = [];
    getData();
  };
  // 改变属性
  const handleChangeAttr = () => {
    trendSelectKeys.value = [];
    getData();
  };
  // 初始化
  const init = async () => {
    params.value.categoryClass2 = cates.value[0].value;
    await getAttrDict();
    params.value.itmAttr = attrOptions.value[0].value;
    getData();
  };

  init();
  return {
    handleChangeCate,
    attrOptions,
    handleChangeAttr,
    trendParams,
    distributionParams,
    overViewParams,
    params,
    handleChangeDate,
    handleChangeSort,
    overViewData,
    handleOverViewCurrentChange,
    overViewPages,
    overViewTotal,
    trendSelectKeys,
    currendTrendData,
    handleChangeTrendSelectKeys,
    currendDistributeData,
    handleChangeTrendTab,
  };
};
