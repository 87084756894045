
import { defineComponent, PropType, computed, ref, watch, nextTick } from 'vue';
import {
  PRODUCTION_STATE_LIST,
  DS_ORDER_ENUM,
} from '@/modules/information-manage/shop-manage/constant';
import {
  TOP_N_CALCUTE_TYPE_ENUM,
  TOP_N_ORDER_ENUM,
  TOP_N_CALCUTE_TYPE_LIST,
  TOP_N_ORDER_LIST,
} from '@/modules/information-manage/shop-manage/views/detail/constant';
import {
  ISalesOverview,
  IShopSalesTrend,
  IShopUpDownTrend,
  IShopTopNSpu,
} from '@/modules/information-manage/shop-manage/api/type.d';
import {
  SALES_TREND_ENUM,
  SALES_TREND_LIST,
  UP_DOWN_SHELF_TREND_ENUM,
  UP_DOWN_SHELF_TREND_LIST,
} from './constant';
import { ITrendDataMap } from './type.d';
import Card from '../card/index.vue';
import DataItem from '../data-item/index.vue';
import TabList from '../tab-list/index.vue';
import LineChart from './components/line-chart.vue';
import BarChart from './components/bar-chart.vue';
import ShopItem from '../shop-item/index.vue';
import { ElTable } from 'element-plus';

export default defineComponent({
  name: 'shopOverview',
  emits: [
    'update:trendCategoryClass',
    'update:upDownCategoryClass',
    'update:shopTopCategoryClass',
    'update:isNew',
    'update:shopTopPageParams',
    'update:shopTopCalcuteType',
    'update:shopTopOrder',
    'on-show-log',
  ],
  components: {
    Card,
    DataItem,
    TabList,
    LineChart,
    BarChart,
    ShopItem,
  },
  props: {
    height: {
      type: Number,
      default: 400,
    },
    trendCategoryClass: {
      type: String,
      default: '',
    },
    upDownCategoryClass: {
      type: String,
      default: '',
    },
    shopTopCategoryClass: {
      type: String,
      default: '',
    },
    shopTopPageParams: {
      type: Object,
      default: () => {
        return {
          pageNum: 1,
          pageSize: 10,
          total: 0,
        };
      },
    },
    shopTopCalcuteType: {
      type: String as PropType<TOP_N_CALCUTE_TYPE_ENUM>,
      default: TOP_N_CALCUTE_TYPE_ENUM.YESTERDAY,
    },
    shopTopOrder: {
      type: Object,
      default: () => {
        return {
          orderColumn: TOP_N_ORDER_ENUM.ALL,
          orderType: '',
        };
      },
    },
    isNew: {
      type: String,
      default: '',
    },
    shopSalesOverviewData: {
      type: Object as PropType<ISalesOverview>,
    },
    shopSalesTrendData: {
      type: Array as PropType<IShopSalesTrend[]>,
      default: () => [],
    },
    shopUpDownTrendData: {
      type: Array as PropType<IShopUpDownTrend[]>,
      default: () => [],
    },
    shopTopData: {
      type: Array as PropType<IShopTopNSpu[]>,
      default: () => [],
    },
    categoryOptions: {
      type: Array as PropType<{ label: string; value: string; }[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const overviewDataList = computed(() => {
      const list = [
        {
          label: '昨日店铺销量',
          value: 0,
          description: '',
          blockColor: '#F5AB18',
          tips: '店铺所有商品在昨日销量总和。',
          valueKey: 'trdSalesSpuNum1d',
        },
        {
          label: '昨日销售额 (万元)',
          value: 0,
          tips: '店铺所有商品昨日销售额总和。注：店铺销售额来自于商品日销售额的累加，商品日销售额=商品日销量X商品最低价。',
          blockColor: '#EA8181',
          description: '',
          valueKey: 'trdSalesSpuAmt1d',
        },
        {
          label: '昨日动销率',
          value: '0%',
          tips: '店铺昨日销量大于0的商品在全店在售商品数中的百分比。',
          blockColor: '#D167EA',
          description: '',
          valueKey: 'itmSalesSpuRate1d',
        },
        {
          label: '昨日在售商品数',
          value: 0,
          tips: '店铺昨日处于在售状态的商品数。',
          blockColor: '#6C67EA',
          description: '',
          valueKey: 'itmSalesSpuCnt1d',
        },
        {
          label: '最近上新商品数',
          value: 0,
          tips: '最近一次上新的新品数量。',
          blockColor: '#4B85FA',
          description: '',
          valueKey: 'itmShelfSpuCntFirst1d',
        },
      ];
      if (props.shopSalesOverviewData) {
        const { shopSalesOverviewData } = props;
        list.forEach((data) => {
          const valueKey = data.valueKey as keyof ISalesOverview;
          if (valueKey === 'itmSalesSpuRate1d') {
            data.value = `${shopSalesOverviewData[valueKey] || 0}%`;
          } else {
            data.value = shopSalesOverviewData[valueKey] as number || 0;
          }
        });
        list[4].description = shopSalesOverviewData.itmShelfFirstDate || '';
      }
      return list;
    });
    // 月度峰值
    const monthlyPeakList = computed(() => {
      const list = [
        {
          label: '销量峰值',
          value: 0,
          description: '',
          blockColor: '#F56262',
          tips: '店铺月销量最大值。',
          valueKey: 'trdSalesSpuNumPeak1m',
          descriptionKey: 'trdSalesSpuNumPeakMo',
        },
        {
          label: '上新商品数峰值',
          value: 0,
          description: '',
          blockColor: '#F58762',
          tips: '月度首次上新商品数最大值。',
          valueKey: 'itmShelfSpuCntFirstPeak1m',
          descriptionKey: 'itmShelfSpuCntFirstPeakMo',
        },
        {
          label: '在售商品数峰值',
          value: 0,
          description: '',
          blockColor: '#6281F5',
          tips: '月度在售商品数最大值（去重后）。',
          valueKey: 'itmSalesSpuCntPeak1m',
          descriptionKey: 'itmSalesSpuCntPeakMo',
        },
      ];
      if (props.shopSalesOverviewData) {
        const { shopSalesOverviewData } = props;
        list.forEach((data) => {
          const valueKey = data.valueKey as keyof ISalesOverview;
          const descriptionKey = data.descriptionKey as keyof ISalesOverview;
          data.value = shopSalesOverviewData[valueKey] as number || 0;
          data.description = shopSalesOverviewData[descriptionKey] as string || '';
        });
      }
      return list;
    });
    // 季度峰值
    const quarterlyPeakList = computed(() => {
      const list = [
        {
          label: '销量峰值',
          value: 0,
          tips: '店铺季度销量最大值。',
          blockColor: '#4BC0FA',
          description: '',
          valueKey: 'trdSalesSpuNumPeak1q',
          descriptionKey: 'trdSalesSpuNumPeakQt',
        },
        {
          label: '上新商品数峰值',
          value: 0,
          tips: '季度首次上新商品数最大值。',
          blockColor: '#6CD9B5',
          description: '',
          valueKey: 'itmShelfSpuCntFirstPeak1q',
          descriptionKey: 'itmShelfSpuCntFirstPeakQt',
        },
        {
          label: '在售商品数峰值',
          value: 0,
          tips: '季度在售商品数最大值（去重后）。',
          blockColor: '#F57AF5',
          description: '',
          valueKey: 'itmSalesSpuCntPeak1q',
          descriptionKey: 'itmSalesSpuCntPeakQt',
        },
      ];
      if (props.shopSalesOverviewData) {
        const { shopSalesOverviewData } = props;
        list.forEach((data) => {
          const valueKey = data.valueKey as keyof ISalesOverview;
          const descriptionKey = data.descriptionKey as keyof ISalesOverview;
          data.value = shopSalesOverviewData[valueKey] as number || 0;
          data.description = shopSalesOverviewData[descriptionKey] as string || '';
        });
      }
      return list;
    });
    const handleTrendCategoryChange = (val: string) => {
      emit('update:trendCategoryClass', val);
    };
    const isNewOption = [...PRODUCTION_STATE_LIST];
    const handleIsNewChange = (val: string) => {
      emit('update:isNew', val);
    };
    // 销售趋势
    const trendState = ref<SALES_TREND_ENUM>(SALES_TREND_ENUM.SALES_VOLUME);
    const trendXAxisData = ref<string[]>([]);
    const trendDataMap = ref<ITrendDataMap>({} as ITrendDataMap);
    trendDataMap.value[SALES_TREND_ENUM.SALES_VOLUME] = {
      seriesData: [],
      yAxisName: '销量',
      legendName: '日销量',
    };
    trendDataMap.value[SALES_TREND_ENUM.VALUE_OF_SALES] = {
      seriesData: [],
      yAxisName: '销售额',
      legendName: '日销售额',
    };
    trendDataMap.value[SALES_TREND_ENUM.NUMBER_OF_GOODS_ON_SALE] = {
      seriesData: [],
      yAxisName: '在售商品数',
      legendName: '日在售商品数',
    };
    watch(
      () => props.shopSalesTrendData,
      () => {
        trendXAxisData.value = [];
        trendDataMap.value[SALES_TREND_ENUM.SALES_VOLUME].seriesData = [];
        trendDataMap.value[SALES_TREND_ENUM.VALUE_OF_SALES].seriesData = [];
        trendDataMap.value[SALES_TREND_ENUM.NUMBER_OF_GOODS_ON_SALE].seriesData = [];
        if (props.shopSalesTrendData.length > 0) {
          props.shopSalesTrendData.forEach((trendData) => {
            const { ds, trdSalesSpuNum1d, trdSalesSpuAmt1d, itmSalesSpuCnt1d } = trendData;
            trendXAxisData.value.push(ds);
            trendDataMap.value[SALES_TREND_ENUM.SALES_VOLUME].seriesData.push(trdSalesSpuNum1d);
            trendDataMap.value[SALES_TREND_ENUM.VALUE_OF_SALES].seriesData.push(trdSalesSpuAmt1d);
            trendDataMap.value[SALES_TREND_ENUM.NUMBER_OF_GOODS_ON_SALE].seriesData.push(itmSalesSpuCnt1d);
          });
        }
      },
      { immediate: true },
    );
    // 上下架趋势
    const upDownTrendState = ref<UP_DOWN_SHELF_TREND_ENUM>(UP_DOWN_SHELF_TREND_ENUM.NUMBER_OF_NEW_PRODUCTS);
    const handleUpDownCategoryChange = (val: string) => {
      emit('update:upDownCategoryClass', val);
    };
    const upDownTrendXAxisData = ref<string[]>([]);
    const upDownTrendDataMap = ref<ITrendDataMap>({} as ITrendDataMap);
    upDownTrendDataMap.value[UP_DOWN_SHELF_TREND_ENUM.NUMBER_OF_NEW_PRODUCTS] = {
      seriesData: [],
      yAxisName: '上新商品数',
      legendName: '上新商品数',
    };
    upDownTrendDataMap.value[UP_DOWN_SHELF_TREND_ENUM.SALES_VOLUME_ON_THE_NEW_DAY] = {
      seriesData: [],
      yAxisName: '上新当日销量',
      legendName: '上新当日销量',
    };
    upDownTrendDataMap.value[UP_DOWN_SHELF_TREND_ENUM.NUMBER_OF_GOODS_OFF_THE_SHELF] = {
      seriesData: [],
      yAxisName: '下架商品数',
      legendName: '下架商品数',
    };
    watch(
      () => props.shopUpDownTrendData,
      () => {
        upDownTrendXAxisData.value = [];
        upDownTrendDataMap.value[UP_DOWN_SHELF_TREND_ENUM.NUMBER_OF_NEW_PRODUCTS].seriesData = [];
        upDownTrendDataMap.value[UP_DOWN_SHELF_TREND_ENUM.SALES_VOLUME_ON_THE_NEW_DAY].seriesData = [];
        upDownTrendDataMap.value[UP_DOWN_SHELF_TREND_ENUM.NUMBER_OF_GOODS_OFF_THE_SHELF].seriesData = [];
        if (props.shopUpDownTrendData.length > 0) {
          props.shopUpDownTrendData.forEach((trendData) => {
            const { ds, itmShelfSpuCntFirst1d, trdSalesSpuNumShelf1d, itmOffshelfSpuCnt1d } = trendData;
            upDownTrendXAxisData.value.push(ds);
            upDownTrendDataMap.value[UP_DOWN_SHELF_TREND_ENUM.NUMBER_OF_NEW_PRODUCTS].seriesData.push(
              itmShelfSpuCntFirst1d,
            );
            upDownTrendDataMap.value[UP_DOWN_SHELF_TREND_ENUM.SALES_VOLUME_ON_THE_NEW_DAY].seriesData.push(
              trdSalesSpuNumShelf1d,
            );
            upDownTrendDataMap.value[UP_DOWN_SHELF_TREND_ENUM.NUMBER_OF_GOODS_OFF_THE_SHELF].seriesData.push(
              itmOffshelfSpuCnt1d,
            );
          });
        }
      },
      { immediate: true },
    );
    // TOP商品榜
    const tableRef = ref<typeof ElTable | null>(null);
    const tableScrollEl = computed(() => {
      if (tableRef.value) {
        const el = tableRef.value.$el as Element;
        const scrollBody = el.getElementsByClassName('el-table__body-wrapper')[0];
        return scrollBody;
      }
      return null;
    });
    const sortList = [
      { label: '销量', value: TOP_N_ORDER_ENUM.SALES_VOLUME },
      { label: '销售额', value: TOP_N_ORDER_ENUM.VALUE_OF_SALES },
      { label: '收藏数', value: TOP_N_ORDER_ENUM.VALUE_OF_COLLECT },
    ];
    const handleChangeSortType = (index: number) => {
      const sort = sortList[index];
      if (
        props.shopTopOrder.orderType === ''
        || props.shopTopOrder.orderColumn !== sort.value
      ) {
        emit('update:shopTopOrder', {
          orderType: DS_ORDER_ENUM.DESC,
          orderColumn: sort.value,
        });
      } else if (props.shopTopOrder.orderType === DS_ORDER_ENUM.DESC) {
        emit('update:shopTopOrder', {
          orderType: DS_ORDER_ENUM.ASC,
          orderColumn: sort.value,
        });
      } else {
        emit('update:shopTopOrder', {
          orderType: '',
          orderColumn: TOP_N_ORDER_ENUM.ALL,
        });
      }
    };
    const handleShopTopCalcuteTypeChange = (val: string) => {
      emit('update:shopTopCalcuteType', val);
    };
    const handleShopTopCategoryChange = (val: string) => {
      emit('update:shopTopCategoryClass', val);
    };
    const handleCurrentChange = (val: number) => {
      const pageParams = Object.assign({}, props.shopTopPageParams);
      pageParams.pageNum = val;
      emit('update:shopTopPageParams', pageParams);
    };
    const handleShowLog = (row: IShopTopNSpu) => {
      emit('on-show-log', row.spu);
    };
    const isShowTable = ref(true);
    watch(() => props.height, () => {
      isShowTable.value = false;
      nextTick(() => {
        isShowTable.value = true;
      });
    }, { immediate: true });
    return {
      trendState,
      trendDataMap,
      PRODUCTION_STATE_LIST,
      SALES_TREND_LIST,
      UP_DOWN_SHELF_TREND_LIST,
      overviewDataList,
      monthlyPeakList,
      quarterlyPeakList,
      isNewOption,
      trendXAxisData,
      upDownTrendState,
      upDownTrendDataMap,
      upDownTrendXAxisData,
      TOP_N_CALCUTE_TYPE_LIST,
      TOP_N_ORDER_LIST,
      DS_ORDER_ENUM,
      sortList,
      tableRef,
      tableScrollEl,
      handleChangeSortType,
      handleShopTopCalcuteTypeChange,
      handleShopTopCategoryChange,
      handleCurrentChange,
      handleTrendCategoryChange,
      handleUpDownCategoryChange,
      handleShowLog,
      handleIsNewChange,
      isShowTable,
    };
  },
});
