
import { defineComponent, PropType, computed } from 'vue';
import {
  DS_ORDER_ENUM,
} from '@/modules/information-manage/shop-manage/constant';
import {
  SALES_TREND_LIST,
  DATE_LIST,
} from './constant';
import Card from '../card/index.vue';
import TabList from '../tab-list/index.vue';
import PieChart from './components/pie-chart.vue';
import LineChart from './components/line-chart.vue';
import { useData } from './composables';

export default defineComponent({
  emits: [],
  components: {
    Card,
    PieChart,
    TabList,
    LineChart,
  },
  props: {
    categoryOptions: {
      type: Array as PropType<{ label: string; value: string; }[]>,
      default: () => [],
    },
  },
  setup(props) {
    const cates = computed(() => props.categoryOptions.filter(v => !['-1', '-99'].includes(v.value)));
    const {
      params,
      trendParams,
      distributionParams,
      overViewParams,
      handleChangeDate,
      handleChangeSort,
      overViewData,
      overViewPages,
      overViewTotal,
      handleOverViewCurrentChange,
      currendDistributeData,
      currendTrendData,
      handleChangeTrendSelectKeys,
      trendSelectKeys,
      handleChangeTrendTab,
      handleChangeAttr,
      attrOptions,
      handleChangeCate,
    } = useData(cates);
    return {
      handleChangeCate,
      cates,
      attrOptions,
      handleChangeAttr,
      params,
      trendParams,
      distributionParams,
      overViewParams,
      handleChangeDate,
      handleChangeSort,
      DATE_LIST,
      overViewData,
      overViewPages,
      overViewTotal,
      handleOverViewCurrentChange,
      SALES_TREND_LIST,
      DS_ORDER_ENUM,
      currendDistributeData,
      currendTrendData,
      handleChangeTrendSelectKeys,
      trendSelectKeys,
      handleChangeTrendTab,
    };
  },
});
