import { ref, Ref } from 'vue';
import { getNewCommodityTrend } from '@/modules/information-manage/shop-manage/api';
import { INewCommodityTrend } from '@/modules/information-manage/shop-manage/api/type.d';
import { datefuns } from 'cx-utils';

export default function useTrend(
  storeId: Ref<string>,
  channelId: Ref<string>,
  date: Ref<string[]>,
  category: Ref<string>,
) {
  // 上新趋势详情
  const newCommodityTrendData = ref<INewCommodityTrend[]>([]);
  // 获取上新趋势详情
  const handleGetNewCommodityTrend = async () => {
    const { data } = await getNewCommodityTrend({
      storeId: storeId.value,
      channelId: channelId.value,
      startDate: date.value[0] ? datefuns.formatTime(date.value[0], 'YYYYMMDD') : '',
      endDate: date.value[1] ? datefuns.formatTime(date.value[1], 'YYYYMMDD') : '',
      categoryClass2: category.value,
    });
    newCommodityTrendData.value = data;
  };
  return { newCommodityTrendData, handleGetNewCommodityTrend };
}
