export enum SALES_TREND_ENUM {
  // 销量
  SALES_VOLUME = 'SALES_VOLUME',
  // 销售额
  VALUE_OF_SALES = 'VALUE_OF_SALES',
  // 在售商品数
  NUMBER_OF_GOODS_ON_SALE = 'NUMBER_OF_GOODS_ON_SALE',
}

export const SALES_TREND_LIST = [
  { label: '销量', value: SALES_TREND_ENUM.SALES_VOLUME },
  { label: '销售额', value: SALES_TREND_ENUM.VALUE_OF_SALES },
  { label: '在售商品数', value: SALES_TREND_ENUM.NUMBER_OF_GOODS_ON_SALE },
];

export enum UP_DOWN_SHELF_TREND_ENUM {
  // 上新商品数
  NUMBER_OF_NEW_PRODUCTS = 'NUMBER_OF_NEW_PRODUCTS',
  // 上新当日销量
  SALES_VOLUME_ON_THE_NEW_DAY = 'SALES_VOLUME_ON_THE_NEW_DAY',
  // 下架商品数
  NUMBER_OF_GOODS_OFF_THE_SHELF = 'NUMBER_OF_GOODS_OFF_THE_SHELF'
}

export const UP_DOWN_SHELF_TREND_LIST = [
  { label: '上新商品数', value: UP_DOWN_SHELF_TREND_ENUM.NUMBER_OF_NEW_PRODUCTS },
  { label: '上新当日销量', value: UP_DOWN_SHELF_TREND_ENUM.SALES_VOLUME_ON_THE_NEW_DAY },
  { label: '下架商品数', value: UP_DOWN_SHELF_TREND_ENUM.NUMBER_OF_GOODS_OFF_THE_SHELF },
];
