import { ref, Ref } from 'vue';
import { getHotSellingAnalysisOverview } from '@/modules/information-manage/shop-manage/api';
import { IHotSellingOverview } from '@/modules/information-manage/shop-manage/api/type.d';

export default function useOverview(
  storeId: Ref<string>,
  channelId: Ref<string>,
  date: Ref<{ startDate: string; endDate: string; }>,
  category: Ref<string>,
) {
  // 商店热销详情
  const hotSellingAnalysisOverviewData = ref<IHotSellingOverview>({
    storeId: '',
    storeName: '',
    channelId: '',
    channelName: '',
    categoryClass1Name: '',
    categoryClass1: '',
    trdSalesSpuAmtHp: '',
    trdSalesSpuCntHp: '',
    trdSalesSpuAvgContribHp: '',
    trdSalesSpuCategoryCntHp: '',
  });
  // 获取商店热销详情
  const handleGetHotSellingAnalysisOverview = async () => {
    const { data } = await getHotSellingAnalysisOverview({
      storeId: storeId.value,
      channelId: channelId.value,
      ...date.value,
      categoryClass2: category.value,
    });
    [hotSellingAnalysisOverviewData.value] = data;
  };
  return { hotSellingAnalysisOverviewData, handleGetHotSellingAnalysisOverview };
}
