
import { defineComponent, PropType, computed, ref } from 'vue';
import Card from '../card/index.vue';
import TabList from '../tab-list/index.vue';
import DataItem from '../data-item/index.vue';
import ShopItem from '../shop-item/index.vue';
import PieChart from './components/pie-chart.vue';
import { useData } from './composables/use-data';
import { QUREY_TYPE_LIST } from './constant';
import { ElTable } from 'element-plus';
import {
  IGoodsDetailItem,
} from './types';

export default defineComponent({
  emits: ['on-show-log'],
  components: {
    Card,
    DataItem,
    ShopItem,
    PieChart,
    TabList,
  },
  props: {
    height: {
      type: Number,
      default: 400,
    },
    categoryOptions: {
      type: Array as PropType<{ label: string; value: string; }[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const cateChartEl = ref<InstanceType<typeof PieChart>>();
    const priceRangeChartEl = ref<InstanceType<typeof PieChart>>();
    const {
      currentCategoryAnalysisData,
      categoryAnalysisParams,
      currentPriceRangeAnalysisData,
      priceRangeAnalysisDataParams,
      overviewDataList,
      goodsDetailParams,
      goodsDetailData,
      goodsDetailTotal,
      handleGoodsDetailCurrentChange,

      params,
      DATE_LIST,
      getData,
    } = useData();
    const reload = () => {
      getData();
      priceRangeChartEl.value?.selectAllLegend();
      cateChartEl.value?.selectAllLegend();
    };
    const handleShowLog = (row: IGoodsDetailItem) => {
      emit('on-show-log', row.spu);
    };
    // 修改pageNum
    const tableRef = ref<typeof ElTable | null>(null);
    const tableScrollEl = computed(() => {
      if (tableRef.value) {
        const el = tableRef.value.$el as Element;
        const scrollBody = el.getElementsByClassName('el-table__body-wrapper')[0];
        return scrollBody;
      }
      return null;
    });
    // 初始化
    const init = () => {
      getData();
    };
    init();

    return {
      reload,
      getData,
      tableScrollEl,
      tableRef,
      currentCategoryAnalysisData,
      categoryAnalysisParams,
      currentPriceRangeAnalysisData,
      priceRangeAnalysisDataParams,
      QUREY_TYPE_LIST,
      goodsDetailParams,
      goodsDetailData,
      goodsDetailTotal,
      handleGoodsDetailCurrentChange,

      handleShowLog,
      params,
      DATE_LIST,
      overviewDataList,
      cateChartEl,
      priceRangeChartEl,
    };
  },
});
